import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';
import { CurrencyOptions } from '../OrderChangeCalculator/OrderChangeCalculator';

function OrderInfoCard({
  order: { orderSeqId, prettyId, createdBy, billingCustomer, createdAt, amount, items, currency },
}) {
  const formatAmount = amount => {
    return currency === CurrencyOptions.EUR ? formatEuro(amount) : formatForint(amount);
  };
  const rows = [
    {
      label: 'Sequential order ID',
      value: `OR${orderSeqId}`,
    },
    {
      label: 'Created by',
      value: routes.user.createLink(createdBy),
    },
    {
      label: 'Customer',
      value: billingCustomer
        ? routes.customer.createLink(billingCustomer.id, billingCustomer.fullName)
        : '',
    },
    {
      label: 'Customer Email',
      value: billingCustomer
        ? routes.customer.createLink(
          billingCustomer.id,
          billingCustomer.fullName,
          billingCustomer.email,
        )
        : '',
    },
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Amount',
      value: formatAmount(amount),
    },
    {
      label: 'Number of Items',
      value: items,
    },
  ];
  return <InfoCard title={prettyId} rows={rows} />;
}

OrderInfoCard.propTypes = {
  order: Types.order.isRequired,
};

export default OrderInfoCard;
