import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export default function useGetV2TicketStatistics(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['event-v2-ticket-statistics', eventId],
        queryFn: async () => {
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}webshop/v0/event/${eventId}/paid-v2tickets`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};