import React, { useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useGetCheckings } from './useGetCheckings';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../common/components/Dashboard/dashboard-statistics/EventsInNextWeek';
import { format } from 'date-fns';
import routes from '../../utils/routes';

function CheckingHistory() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log({ newPage });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isSuccess, isError, isLoading } = useGetCheckings({ page, rowsPerPage });
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Card Number</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Customer Email</TableCell>
            <TableCell>Profile Picture</TableCell>
            <TableCell>Check Time</TableCell>
            <TableCell>Reading Type</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Checked By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && (
            <TableRow>
              <TableCell colSpan={8}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Typography>Something went wrong!</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow
              style={{
                position: 'relative',
                height: '110px',
              }}
            >
              <TableCell colSpan={8}>
                <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            (data.checkings.length > 0 ? (
              data.checkings.map(checking => {
                const {
                  _id,
                  cardNumber,
                  customerEmail,
                  customerFullName,
                  createdDate,
                  isLast12Hours,
                  isSingleReadingPerUser,
                  profilePicture,
                  isEventReading,
                  userId: user,
                  customerId,
                  eventId: event,
                } = checking;

                const { url } = profilePicture || {};
                const { id: userId, profile } = user;
                const { firstName, lastName } = profile;
                const userFullName = `${firstName} ${lastName}`;
                const userLink = routes.user.createLink({
                  id: userId,
                  fullName: userFullName,
                });

                const customerNameLink = routes.customer.createLink(customerId, customerFullName);
                const customerEmailLink = routes.customer.createLink(customerId, customerEmail);

                const { _id: eventId, name: eventName } = event || {};
                const eventNameLink = routes.event.createLink(eventId, eventName);

                return (
                  <TableRow key={_id}>
                    <TableCell>{cardNumber}</TableCell>
                    <TableCell>{customerNameLink}</TableCell>
                    <TableCell>{customerEmailLink}</TableCell>
                    <TableCell>
                      {url ? (
                        <img src={url} alt="Profile Picture" width="50px" />
                      ) : (
                        'No Profile Picture'
                      )}
                    </TableCell>
                    <TableCell>{format(new Date(createdDate), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      <Typography style={{ alignSelf: 'center' }}>
                        {isLast12Hours
                          ? 'Last 12 Hours Reading'
                          : isSingleReadingPerUser
                          ? 'Single Reading/User'
                          : isEventReading
                          ? 'Event Reading'
                          : 'Unknown Type'}
                      </Typography>
                    </TableCell>
                    <TableCell>{event ? eventNameLink : '-'}</TableCell>
                    <TableCell>{userLink}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <Alert severity="warning">
                    <AlertTitle>Checking</AlertTitle>
                    <Typography style={{ fontSize: '16px' }}>
                      There is no checking to display!
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={data?.checkingNumber}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default CheckingHistory;
