import React from 'react';

import ReadElbCard from '../ReadElbCard';
import { useCheckPrivilege } from '../../../lib/hooks';

import Privileges from '../../../../utils/access-control/privileges.json';
import { Grid } from '@material-ui/core';

function CheckElbCardToolBar({
  cardNumber,
  setCardNumber,
  setReceivedElbCard,
  setStateOfQuery,
  setShowButtons,
  setApprovedIcon,
}) {
  const access = useCheckPrivilege(Privileges.READ_ANY_ELB_CARD);

  const handleReceivedElbCard = data => {
    setReceivedElbCard(data);
  };

  const handleStateOfQuery = bool => {
    setStateOfQuery(bool);
  };

  if (!access) {
    return null;
  }
  return (
    <div>
      <Grid
        style={{ display: 'flex', alignItems: 'center' }}
        container
        direction="row"
        justify="center"
      >
        <ReadElbCard
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          sendReceivedElbCardData={handleReceivedElbCard}
          sendStateOfQuery={handleStateOfQuery}
          setShowButtons={setShowButtons}
          setApprovedIcon={setApprovedIcon}
        />
      </Grid>
    </div>
  );
}

export default CheckElbCardToolBar;
