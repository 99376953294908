import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetCheckings = ({ page, rowsPerPage }) => {
    return useQuery({
        queryKey: ['all-checkings', { page, rowsPerPage }],
        queryFn: async () => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}checking/get-all-checking?page=${page}&rowsPerPage=${rowsPerPage}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};