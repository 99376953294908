import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';

import { GET_TRANSACTIONS } from '../../graphql';

import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import { CollectionTable, Table } from '../../../common/components';
import TransactionType from './TransactionType';
import { CurrencyOptions } from '../../../orders/components/OrderChangeCalculator/OrderChangeCalculator';

const columns = [
  {
    name: 'transactionSeqId',
    label: 'ID',
    options: {
      renderCell: (value, { transactionSeqId }) => {
        return transactionSeqId ? `TR${transactionSeqId}` : null;
      },
      filterType: 'textField',
    },
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filterOptions: Object.values(TransactionType),
      filterType: 'dropdown',
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'amount',
    label: 'Amount',
    options: {
      renderCell: (value, { amount, currency }) =>
        `${amount} ${currency === CurrencyOptions.EUR ? '€' : 'Ft'}`,
    },
  },
  {
    name: 'source.name',
    label: 'From',
    autocompleteType: AutocompleteTypes.ACCOUNT,
    options: {
      filterType: 'autocomplete',
    },
  },
  {
    name: 'target.name',
    label: 'To',
    autocompleteType: AutocompleteTypes.ACCOUNT,
    options: {
      filterType: 'autocomplete',
    },
  },
  {
    name: 'description',
    label: 'Note',
    type: 'longText',
    options: {
      filterType: 'textField',
    },
  },
];

function TransactionsTable({ transactions, inline = false }) {
  const [sortModel, setSortModel] = useState({
    name: 'transactionSeqId',
    sort: 'desc',
  });

  return (
    <CollectionTable>
      <Table
        title="Transactions List"
        collectionName="transactions"
        query={transactions ? undefined : GET_TRANSACTIONS}
        data={transactions}
        columns={columns}
        inline={inline}
        sortModel={sortModel}
        onSortModelChange={model => setSortModel(model)}
      />
    </CollectionTable>
  );
}

TransactionsTable.propTypes = {
  transactions: Types.transactions,
  inline: PropTypes.bool,
};

export default TransactionsTable;
