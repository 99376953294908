import React, { useEffect, useState } from 'react';
import { Link, Button, ListItemText, Card, CardContent, Typography, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';

import { useCheckPrivileges, useSnackbar } from '../../../lib/hooks';
import {
  useDeleteEventForm,
  useGetEventExchangeRate,
  useGetV2TicketStatistics,
} from '../../../events/hooks';

import { InfoCardRow } from '../../../common/components';
import useGetEventTicketTypes from '../../../event-ticket-types/useGetEventTicketTypes';
import { Edit } from '@material-ui/icons';
import CreateOrEditEventTicketType from '../../../event-ticket-types/CreateOrEditEventTicketType';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useGetCardReadingNumbers from '../../hooks/useGetCardReadingNumbers';

function EventInfoCard({
  event: {
    id,
    forSale,
    from,
    until,
    location,
    totalSalesInHuf,
    totalSalesInEur,
    totalSalesInHufSum,
    totalSalesInEurSum,
    name,
    ticketStats,
    ticketLimit,
    createdAt,
    createdBy,
    billingAccount,
    form,
    isExternal,
  },
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState();
  const handleOpen = id => {
    setOpen(id);
  };
  const [billingAccountAccess, formSubmissionAccess, deleteFormAccess] = useCheckPrivileges(
    Privileges.READ_ANY_BILLING_ACCOUNT,
    Privileges.READ_ANY_FORM_SUBMISSION,
    Privileges.DELETE_EVENT_FORM,
  );
  const { data, isLoading } = useGetEventTicketTypes(id);
  console.log({ data });

  const [deleteForm] = useDeleteEventForm({ id: id });
  const deleteEventForm = async () => {
    await deleteForm({ id: id });
    enqueueSnackbar('Form deleted!', 'success');
  };

  const { data: exchangeRateData, isSuccess } = useGetEventExchangeRate(id);
  const { data: v2TicketStatistics } = useGetV2TicketStatistics(id);

  const {
    data: numberOfCardReadingsData,
    isLoading: isCardReadingNumerLoading,
    isSuccess: isCardReadingNumerSuccess,
  } = useGetCardReadingNumbers(id);

  const rows = [
    {
      label: 'For Sale',
      value: forSale ? (
        <CheckCircleIcon htmlColor="green" style={{ fontSize: 24 }} />
      ) : (
        <CancelIcon htmlColor="red" style={{ fontSize: 24 }} />
      ),
    },
    {
      label: 'From',
      value: formatDateTime(from),
    },
    {
      label: 'Until',
      value: formatDateTime(until),
    },
    {
      label: 'Location',
      value: location,
    },

    {
      label: 'Active Tickets',
      value: ticketStats.active,
    },
    {
      label: 'Reserved Tickets',
      value: ticketStats.reserved,
    },
    {
      label: 'Active V2 Tickets',
      value: v2TicketStatistics?.activeV2Tickets,
    },
    {
      label: 'Sold V2 Tickets',
      value: v2TicketStatistics?.soldV2Tickets,
    },
    {
      label: 'Ticket Types Limit',
      value: ticketStats.limit,
    },
    {
      label: 'All Ticket Limit',
      value: ticketLimit || '∞',
    },
    {
      label: 'Sold Tickets',
      value: ticketStats.sold,
    },
    {
      label: 'Sales made in HUF',
      value: formatForint(Math.round(totalSalesInHuf / 10) * 10),
    },
    {
      label: 'Total Sales in HUF',
      value: formatForint(Math.round(totalSalesInHufSum / 10) * 10),
    },
    {
      label: 'Sales made in EUR',
      value: formatEuro(totalSalesInEur),
    },
    {
      label: 'Total Sales in EUR',
      value: formatEuro(totalSalesInEurSum),
    },
    {
      label: 'External',
      value: isExternal ? (
        <CheckCircleIcon htmlColor="green" style={{ fontSize: 24 }} />
      ) : (
        <CancelIcon htmlColor="red" style={{ fontSize: 24 }} />
      ),
    },
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Created by',
      value: routes.user.createLink(createdBy),
    },
  ];
  if (billingAccountAccess) {
    rows.push({
      label: 'Billing with',
      value: billingAccount
        ? routes.billingAccount.createLink(billingAccount.id, billingAccount.name)
        : 'Manually',
    });
  }
  if (formSubmissionAccess) {
    rows.push({
      label: 'Form',
      value: form ? (
        <List style={{ paddingTop: 0 }}>
          <ListItem style={{ padding: 0 }}>
            <Link href={form.submissionsUrl} target="_blank">
              Go to HubSpot
            </Link>
          </ListItem>
          {deleteFormAccess && (
            <ListItem style={{ padding: 0, marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={() => deleteEventForm(form)}>
                Delete form
              </Button>
            </ListItem>
          )}
        </List>
      ) : (
        'None'
      ),
    });
  }
  if (data?.length > 0) {
    rows.push({
      label: 'Event Ticket Types',
      value: (
        <List style={{ paddingTop: 0 }}>
          {data.map(eventTicketType => {
            console.log({ eventTicketType });
            return (
              <ListItem key={eventTicketType._id} style={{ padding: 0 }}>
                - <ListItemText primary={eventTicketType.name} />{' '}
                <Edit
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    console.log({ event: event.currentTarget });
                    handleOpen(eventTicketType._id);
                  }}
                />
                <CreateOrEditEventTicketType
                  initialName={eventTicketType.name}
                  eventId={eventTicketType.eventId}
                  open={open === eventTicketType._id}
                  setOpen={setOpen}
                  id={eventTicketType._id}
                />
              </ListItem>
            );
          })}
        </List>
      ),
    });
  }

  if (isSuccess) {
    rows.push({
      label: 'Exchange Rate',
      value: exchangeRateData.exchangeRate || 'No unique exchange rate data for event',
    });
  }

  if (isCardReadingNumerSuccess) {
    rows.push({
      label: 'Number Of Card Readings',
      value: numberOfCardReadingsData.numberOfCardReadings,
    });
    rows.push({
      label: 'Number Of Unique Card Readings',
      value: numberOfCardReadingsData.numberOfUniqueCardReadings,
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {name}
        </Typography>
        {rows.map(({ label, value }) => (
          <Grid item key={label} style={{ margin: '8px' }}>
            <InfoCardRow label={label} value={value} />
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
}

EventInfoCard.propTypes = {
  event: Types.event.isRequired,
};

export default EventInfoCard;
