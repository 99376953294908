import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_CUSTOMER } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import {
  CustomerToolbar,
  CustomerInfoCard,
  CustomerELBCardsTable,
  CustomerProfilePictureHistory,
} from '..';
import CustomerOrders from './CustomerOrders';
import { useQuery } from '@apollo/react-hooks';
import NotesTable from '../../../notes/NotesTable.component';
import CreateOrEditNote from '../../../notes/CreateOrEditNote.component';

function Customer({
  match: {
    params: { id },
  },
}) {
  const { refetch } = useQuery(GET_CUSTOMER, {
    variables: {
      id,
    },
  });
  return (
    <WithDocumentQuery id={id} query={GET_CUSTOMER}>
      {customer => (
        <Document
          toolbar={
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <CustomerToolbar customer={customer} refetch={refetch} />
              <CreateOrEditNote customerId={id} />
            </div>
          }
          infoCard={<CustomerInfoCard customer={customer} />}
          table={<CustomerELBCardsTable elbCards={customer.elbCards} />}
          secondTable={
            <div>
              <CustomerOrders customer={customer} />
              <CustomerProfilePictureHistory customer={customer} />
              <NotesTable id={id} relatedField={'customer'} />
            </div>
          }
        />
      )}
    </WithDocumentQuery>
  );
}

Customer.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Customer;
