import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useUpdateNote(noteId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['update-note'],
        mutationFn: async (payload) => {
            const ELB_BACKOFFICE_ADMIN_APP_TOKEN = localStorage.getItem('token')
            payload.token = ELB_BACKOFFICE_ADMIN_APP_TOKEN
            const response = await axios.put(`${REACT_APP_SERVER_URL}notes/${noteId}`, payload);
            return response?.data;
        },
        onSuccess: () =>
            queryClient.invalidateQueries(['notes']),
    });
}
