import React from 'react';
import { Box } from '@material-ui/core';
import NotesTable from './NotesTable.component';

function NotesPage() {
  return (
    <Box>
      <NotesTable relatedField={undefined} />
    </Box>
  );
}

export default NotesPage;
