import gql from 'graphql-tag';

import { AUDIT_LOG_FRAGMENT } from '../audit/graphql';
import { ORDER_FRAGMENT } from '../orders/graphql';
import { ACCOUNT_FRAGMENT } from '../accounts/graphql';
import { TRANSACTION_FRAGMENT } from '../transactions/graphql';
import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const USER_WITHOUT_ACCOUNT_FRAGMENT = gql`
  fragment UserWithoutAccountFragment on User {
    id
    email
    firstName
    lastName
    fullName
    createdAt
    role {
      id
      name
    }
    isActive
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    ...UserWithoutAccountFragment
    account {
      ...AccountFragment
    }
  }
  ${USER_WITHOUT_ACCOUNT_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

export const GET_PROFILE = gql`
  query GetProfile($id: String!) {
    user(id: $id) {
      ...UserFragment
    }

    orders(options: {}) {
      entries {
        ...OrderFragment
      }
    }

    accountAuditLogs(options: {}) {
      entries {
        ...AuditLogFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${AUDIT_LOG_FRAGMENT}
`;

export const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      ...UserFragment
    }

    getOrdersByUser(id: $id, options: {}) {
      entries {
        ...OrderFragment
      }
    }

    getAccountAuditLogsByAuditedEntity(userId: $id, options: {}) {
      entries {
        ...AuditLogFragment
      }
    }

    transactionsByUser(userId: $id, options: {}) {
      entries {
        ...TransactionFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${AUDIT_LOG_FRAGMENT}
  ${TRANSACTION_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers($options: OptionsInput) {
    users(options: $options) @connection(key: "users") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...UserFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GET_OTHER_USER = gql`
  query GetOtherUser($id: String!) {
    user(id: $id) {
      ...UserWithoutAccountFragment
    }
  }
  ${USER_WITHOUT_ACCOUNT_FRAGMENT}
`;

export const GET_OTHER_USERS = gql`
  query GetOtherUsers($options: OptionsInput) {
    users(options: $options) @connection(key: "users") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...UserWithoutAccountFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${USER_WITHOUT_ACCOUNT_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: CreateUserInput) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const INACTIVATE_USER = gql`
  mutation InactivateUser($id: String!) {
    updateUser(data: { isActive: false, id: $id }) {
      id
      isActive
    }
  }
`;

export const SEND_ACTIVATION_EMAIL = gql`
  mutation SendActivationEmail($email: String!) {
    sendActivationMail(email: $email)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput) {
    updateUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
