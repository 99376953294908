import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useReadElbCard(cardNumber) {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['read-elb-card', cardNumber],
    queryFn: async () => {
      if (cardNumber) {
        const response = await axios.get(
          `${REACT_APP_SERVER_URL}checking/search?searchText=${cardNumber}`,
        );
        const data = await response.data;
        return data;
      }
      return
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000
  })
}
