import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as Types from '../../../../utils/propTypes';

import { useEffectOnSuccess } from '../../../lib/hooks';
import { useCompleteOrderWithPaidAmounts } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';
import { CurrencyOptions, PaymentModes } from '../OrderChangeCalculator/OrderChangeCalculator';

function FinalizeOrder({
  order,
  paidAmounts,
  terminal,
  paymentMode,
  currencyOption,
  exchangeRate,
}) {
  const history = useHistory();

  const [completeOrderWithPaidAmounts, res] = useCompleteOrderWithPaidAmounts({
    payment: {
      cashSubtotal: paidAmounts.cash,
      cardSubtotal: paidAmounts.card,
    },
    terminal,
  });

  useEffectOnSuccess(res, () => {
    history.push('/order-edit');
  });

  const buttonProps = {
    variant: 'contained',
    color: 'primary',
    disabled: order.isEmpty,
  };

  const mapAmountFromPaymentMode = () => {
    if (paymentMode === PaymentModes.CASH) {
      return {
        payment: {
          cashSubtotal: fullPrice,
          cardSubtotal: 0,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else if (paymentMode === PaymentModes.CARD) {
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: fullPrice,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else if (paymentMode === PaymentModes.MIXED) {
      return {
        payment: {
          cashSubtotal: parseFloat(paidAmounts.cash),
          cardSubtotal: parseFloat(paidAmounts.card),
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else {
      // We should not be here in the else branch
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: 0,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    }
  };

  const [fullPrice, setFullPrice] = useState(0);
  useEffect(() => {
    const ticketsPriceInHuf = order.tickets
      .filter(t => t.status === 'Pending')
      .reduce((sum, { priceInHuf }) => sum + priceInHuf, 0);
    const elbCardsPriceInHuf = order.elbCards
      .filter(e => e.status === 'Pending')
      .reduce((sum, { type: { priceInHuf } }) => sum + priceInHuf, 0);
    const fullPriceInHuf = elbCardsPriceInHuf + ticketsPriceInHuf;

    const ticketsPriceInEur = order.tickets
      .filter(t => t.status === 'Pending')
      .reduce((sum, { priceInEur }) => sum + priceInEur, 0);
    const elbCardsPriceInEur = order.elbCards
      .filter(e => e.status === 'Pending')
      .reduce((sum, { type: { priceInEur } }) => sum + priceInEur, 0);
    const fullPriceInEur = elbCardsPriceInEur + ticketsPriceInEur;

    const newFullPrice =
      currencyOption === CurrencyOptions.HUF
        ? fullPriceInHuf + parseInt((fullPriceInEur * exchangeRate).toFixed(0))
        : parseFloat((fullPriceInHuf / exchangeRate).toFixed(2)) + fullPriceInEur;
    setFullPrice(newFullPrice);
  }, [order, paidAmounts]);

  const calculateDisabledStateOfButton = () => {
    switch (paymentMode) {
      case PaymentModes.CASH: {
        return false;
      }
      case PaymentModes.CARD: {
        return false;
      }
      case PaymentModes.MIXED: {
        const amountsEquality =
          parseFloat(paidAmounts.cash) + parseFloat(paidAmounts.card) === fullPrice;
        const result = !(amountsEquality && terminal !== null);
        return result;
      }
      default:
        return false;
    }
  };

  return (
    <ActionButtonWithConfirmation
      action={() => completeOrderWithPaidAmounts(mapAmountFromPaymentMode())}
      result={res}
      buttonText="Finalize"
      buttonProps={buttonProps}
      successMessage="Order has been completed!"
      disabled={calculateDisabledStateOfButton()}
    />
  );
}

FinalizeOrder.propTypes = {
  order: Types.order.isRequired,
};

export default FinalizeOrder;
