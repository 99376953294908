import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../products.constants';


export default function useUpdateProduct(id) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['update-product'],
        mutationFn: async payload => {
            console.log(payload);
            const token = localStorage.getItem('token')
            const response = await PRODUCTS_API_AXIOS_INSTANCE.patch(
                `/${PRODUCTS_API_ENDPOINTS.PRODUCTS_CONTROLLER_BASE_URL}/${id}`,
                payload,
                { headers: { Authorization: token } }
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
        onSuccess: () => Promise.all([
            queryClient.invalidateQueries(['product-by-id']),
            queryClient.invalidateQueries(['product-revision-by-id'])
        ])
    });
}
