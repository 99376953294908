import React from 'react';

import routes from '../../../../utils/routes';
import { Table, CollectionTable } from '../../../common/components';
import { GET_TERMINALS } from '../../graphql';
import { formatEuro, formatForint } from '../../../../utils/formatters';

const renderAccountCell = account => {
  return account ? routes.account.createLink(account.id, account.name) : 'No assigned account';
};

const renderAccountBalanceInEurCell = account => {
  return account ? formatEuro(account.balanceInEur) : 'No assigned account';
};

const renderAccountBalanceInHufCell = account => {
  return account
    ? account.balance > 0
      ? formatForint(Math.round(account.balance / 10) * 10)
      : account.balanceInHuf > 0
      ? formatForint(Math.round(account.balanceInHuf / 10) * 10)
      : 'No available balance'
    : 'No assigned account';
};

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      renderCell: (value, { id }) => routes.terminal.createLink(id, value),
      filterType: 'textField',
    },
  },
  {
    name: 'account',
    label: 'Assigned account',
    options: {
      renderCell: account => renderAccountCell(account),
    },
  },
  {
    name: 'account',
    label: 'Assigned account balance (HUF)',
    options: {
      renderCell: account => renderAccountBalanceInHufCell(account),
    },
  },
  {
    name: 'account',
    label: 'Assigned account balance (EUR)',
    options: {
      renderCell: account => renderAccountBalanceInEurCell(account),
    },
  },
];

function TerminalsTable() {
  return (
    <CollectionTable>
      <Table
        title="Terminals List"
        collectionName="terminals"
        query={GET_TERMINALS}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default TerminalsTable;
