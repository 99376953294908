import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  //Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //FormControlLabel,
  //Switch,
  TextField,
} from '@material-ui/core';

import { /* useAddElbCardProductToOrder, */ useAddProductToOrder } from '../../../hooks';

import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { ProductsSelect } from '../../../../products/components';
import { FormikCustomerAutocomplete } from '../../../../customers/components';

function AddElbCardProductToOrder({ elbCard = {} }) {
  //const { cardNumber, customer, type } = elbCard;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //const [add] = useAddElbCardProductToOrder();
  const [add] = useAddProductToOrder(handleClose);

  // const isNumeric = numeric => !isNaN(numeric) && !isNaN(parseFloat(numeric));
  // const formik = useFormik({
  //   initialValues: {
  //     elbCardType: type || null,
  //     customer: customer || null,
  //     elbCardNumber: cardNumber || '',
  //     isWithoutPhysicalCardToggle: false,
  //     isAddPhysicalCardToOnlineCard: false,
  //     // productId: null,
  //   },
  //   validationSchema: yup.object({
  //     elbCardType: yup.string().required(`ELB Card type is required!`),
  //     customer: yup.string().required(`Customer is required!`),
  //     elbCardNumber: yup.string().test('elbCardNumber', 'ELB card number is invalid!', input => {
  //       // ELB card number is no optional
  //       if (!input) return true;
  //       if (input.length === 0) {
  //         return true;
  //       }

  //       const isMinLength = input.length === 6;
  //       const isFirstFiveCharsOk = [...input.slice(0, 5)].every(number => isNumeric(number));
  //       const lastChar = input.slice(-1);
  //       const isLastCharacterUppercase =
  //         isNumeric(lastChar) === false && lastChar === lastChar.toUpperCase();
  //       const isGoodFormat = isMinLength && isFirstFiveCharsOk && isLastCharacterUppercase;
  //       return isGoodFormat;
  //     }),
  //     isWithoutPhysicalCardToggle: yup.bool().when(['elbCardNumber'], {
  //       is: elbCardNumber => {
  //         // ELB card number is no optional
  //         if (!elbCardNumber) return false;
  //         if (elbCardNumber.length === 0) {
  //           return false;
  //         }

  //         const isMinLength = elbCardNumber.length === 6;
  //         const isFirstFiveCharsOk = [...elbCardNumber.slice(0, 5)].every(number =>
  //           isNumeric(number),
  //         );
  //         const lastChar = elbCardNumber.slice(-1);
  //         const isLastCharacterUppercase =
  //           isNumeric(lastChar) === false && lastChar === lastChar.toUpperCase();
  //         const isGoodFormat = isMinLength && isFirstFiveCharsOk && isLastCharacterUppercase;
  //         return isGoodFormat;
  //       },
  //       then: yup
  //         .bool()
  //         .oneOf([false], 'Toggle must be NOT checked in case of ELB card number input!'),
  //       otherwise: yup
  //         .bool()
  //         .required()
  //         .oneOf([true], 'Toggle must be checked!'),
  //     }),
  //     isAddPhysicalCardToOnlineCard: yup.bool().when(['elbCardType'], {
  //       is: elbCardType => {
  //         if (elbCardType) {
  //           if (elbCardType.toLowerCase().includes('extra physical')) {
  //             return true;
  //           } else return false;
  //         } else return false;
  //       },
  //       then: yup
  //         .bool()
  //         .oneOf([true], 'Checkbox must BE checked in case of ELB card number input!'),
  //     }),
  //     productId: yup.number().required(),
  //   }),
  //   onSubmit: async ({ elbCardType, customer, elbCardNumber, ...otherValues }) => {
  //     console.log({ elbCardType, customer, elbCardNumber, ...otherValues });
  //     const variables = {
  //       elbCardType,
  //       customer,
  //       elbCardNumber: `ELB${elbCardNumber}`,
  //       ...otherValues,
  //     };

  //     // Remove isWithoutPhysicalCardToggle from variables
  //     const { isWithoutPhysicalCardToggle, ...restVariables } = variables;
  //     console.log({ restVariables });
  //     return add(restVariables);
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      productId: null,
      name: null,
      description: null,
      priceInHuf: null,
      priceInEur: null,
      type: 'elb-card',
      imageUrl: null,
      eventId: null,
      isWebshopPurchasable: false,
      includedInOrderEmail: false,
      isMembershipGranted: false,
      membershipExpiresInDays: null,
      customer: null,
    },
    validationSchema: yup.object({
      productId: yup.string().required(),
      name: yup.string().required(),
      description: yup.string().required(),
      priceInHuf: yup
        .number()
        .nullable()
        .optional(),
      priceInEur: yup
        .number()
        .nullable()
        .optional(),
      type: yup.string().required(),
      imageUrl: yup.string().nullable(),
      eventId: yup.string().nullable(),
      isWebshopPurchasable: yup.boolean(),
      includedInOrderEmail: yup.boolean(),
      isMembershipGranted: yup.boolean(),
      membershipExpiresInDays: yup.number().nullable(),
      customer: yup.string().required(),
    }),
    onSubmit: async () => {
      return add(formik.values);
    },
  });

  const onCustomerSelect = customer => {
    formik.setFieldValue('customer', customer.id);
  };
  const handleCustomerChange = useCallback(
    (_, newCustomer, handleChange) => {
      handleChange();
      onCustomerSelect(newCustomer);
    },
    [onCustomerSelect],
  );

  const onProductSelect = product => {
    if (product !== null) {
      // formik.setFieldValue('elbCardType', product.name ? product.name : null);
      formik.setFieldValue('productId', product._id ? product._id : null);
      formik.setFieldValue('name', product.name ? product.name : null);
      formik.setFieldValue('description', product.description ? product.description : null);
      formik.setFieldValue('priceInHuf', product.priceInHuf ? product.priceInHuf : null);
      formik.setFieldValue('priceInEur', product.priceInEur ? product.priceInEur : null);
      formik.setFieldValue('imageUrl', product.imageUrl ? product.imageUrl : null);
      formik.setFieldValue('eventId', product.eventId ? product.eventId : null);
      formik.setFieldValue('isWebshopPurchasable', product.isWebshopPurchasable);
      formik.setFieldValue('includedInOrderEmail', product.includedInOrderEmail);
      formik.setFieldValue('isMembershipGranted', product.isMembershipGranted);
      formik.setFieldValue(
        'membershipExpiresInDays',
        product.membershipExpiresInDays ? product.membershipExpiresInDays : null,
      );
    }
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Add ELB Card
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add ELB Card</DialogTitle>
        <Formik initialValues={formik.initialValues}>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  marginBottom={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                >
                  <ProductsSelect type={'elb-card'} onProductSelect={onProductSelect} />
                </Box>
                <FormikCustomerAutocomplete
                  name="customer"
                  onChange={handleCustomerChange}
                  toggleElbCard={false}
                  InputProps={{
                    variant: 'outlined',
                    fullWidth: true,
                    margin: 'normal',
                  }}
                />
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="name"
                    name="name"
                    type="hidden"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="description"
                    name="description"
                    type="hidden"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="priceInHuf"
                    name="priceInHuf"
                    type="hidden"
                    value={formik.values.priceInHuf}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="priceInEur"
                    name="priceInEur"
                    type="hidden"
                    value={formik.values.priceInEur}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField id="type" name="type" type="hidden" value={formik.values.type} />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="imageUrl"
                    name="imageUrl"
                    type="hidden"
                    value={formik.values.imageUrl}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="eventId"
                    name="eventId"
                    type="hidden"
                    value={formik.values.eventId}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="isWebshopPurchasable"
                    name="isWebshopPurchasable"
                    type="hidden"
                    value={formik.values.isWebshopPurchasable}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="includedInOrderEmail"
                    name="includedInOrderEmail"
                    type="hidden"
                    value={formik.values.includedInOrderEmail}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="isMembershipGranted"
                    name="isMembershipGranted"
                    type="hidden"
                    value={formik.values.isMembershipGranted}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="membershipExpiresInDays"
                    name="membershipExpiresInDays"
                    type="hidden"
                    value={formik.values.membershipExpiresInDays}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="customer"
                    name="customer"
                    type="hidden"
                    value={formik.values.customer}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="productId"
                    name="productId"
                    type="hidden"
                    value={formik.values.productId}
                    onChange={formik.handleChange}
                  />
                </Box>
                {/* <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  style={{ marginTop: '8px' }}
                >
                  <TextField
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ margin: '8px', width: '550px' }}
                    id="elbCardNumber"
                    name="elbCardNumber"
                    label="ELB Card number"
                    type="text"
                    value={formik.values.elbCardNumber}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.isWithoutPhysicalCardToggle}
                        onChange={formik.handleChange}
                        name="isWithoutPhysicalCardToggle"
                        id="isWithoutPhysicalCardToggle"
                        color="primary"
                      />
                    }
                    label="Without physical card"
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <FormControlLabel
                    control={<Checkbox checked={formik.values.isAddPhysicalCardToOnlineCard} />}
                    onChange={formik.handleChange}
                    name="isAddPhysicalCardToOnlineCard"
                    id="isAddPhysicalCardToOnlineCard"
                    label="Are you sure you want to add a physical card to already existing online card?"
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="elbCardType"
                    name="elbCardType"
                    type="hidden"
                    value={formik.values.elbCardType}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="customer"
                    name="customer"
                    type="hidden"
                    value={formik.values.customer}
                    onChange={formik.handleChange}
                  />
                </Box>*/}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isValid && !formik.dirty}
              >
                Add ELB Card
              </Button>
            </DialogActions>
          </form>
        </Formik>
      </Dialog>
    </>
  );
}

export default AddElbCardProductToOrder;
