import React, { useEffect, useState } from 'react';
import { useCreateElbCardType } from '../../hooks';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { MAX_INTEGER } from '../../../../utils/validationSchemas';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useLatestExchangeRate from '../../../exchangeRate/useLatestExchangeRate';
import { useSnackbar } from '../../../lib/hooks';
function CreateELBCardTypeWithCurrency({ refetchQueries }) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: latestExchangeRate } = useLatestExchangeRate();
  const [create, result] = useCreateElbCardType(refetchQueries ? { refetchQueries } : undefined);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const limitAndPriceValidation = yup
    .number()
    .max(MAX_INTEGER)
    .min(0);
  const formik = useFormik({
    initialValues: {
      name: '',
      priceInHuf: null,
      priceInEur: null,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(2)
        .max(100)
        .required('Name is required'),
      price: yup
        .number()
        .max(MAX_INTEGER)
        .min(0)
        .optional()
        .nullable(),
      priceInEur: yup
        .number()
        .max(MAX_INTEGER)
        .min(0)
        .optional()
        .nullable(),
    }),
    onSubmit: create,
  });

  useEffect(() => {
    if (result?.data && result.called) {
      handleClose();
      enqueueSnackbar('ELB Card type was successfully created', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
    if (result.error) {
      enqueueSnackbar('ELB Card type could not be created', 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [result]);

  const calculateHufFromEur = eurPrice => {
    const hufPrice = parseFloat((eurPrice * latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('priceInHuf', hufPrice);
  };

  const calculateEurFromHuf = hufPrice => {
    const eurPrice = parseFloat((hufPrice / latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('priceInEur', eurPrice);
  };

  return (
    <Box>
      <Button
        style={{ marginLeft: '4px' }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Add ELB Card Type
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Add ELB Card Type</DialogTitle>
        <DialogContent>
          <form>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                name="name"
                id="name"
                type="text"
                required
                autoFocus
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Global exchange rate: {latestExchangeRate?.exchangeRate} HUF/EUR
              </Typography>

              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInHuf"
                  name="priceInHuf"
                  label="Price (HUF)"
                  type="number"
                  min="0"
                  value={formik.values.priceInHuf}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateEurFromHuf(formik.values.priceInHuf)}
                disabled={!formik.values.priceInHuf}
              >
                Calculate EUR from HUF input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInEur"
                  name="priceInEur"
                  label="Price (EUR)"
                  type="number"
                  min="0"
                  fullWidth
                  value={formik.values.priceInEur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateHufFromEur(formik.values.priceInEur)}
                disabled={!formik.values.priceInEur}
              >
                Calculate HUF from EUR input field
              </Button>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
            Add ELB Card Type
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateELBCardTypeWithCurrency;
