import React from 'react';
import { useHistory } from 'react-router';
import { useGetPaymentStatuses } from '../../hooks/useGetPaymentStatuses';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { FillerSpinner } from '../../../lib/components';
import { Alert, AlertTitle } from '@material-ui/lab';

function PaymentStatusSelect({ status, setStatus, isStatistics }) {
  console.log({ status, isStatistics });
  const { data, isLoading, isSuccess, isError } = useGetPaymentStatuses();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const pathname = history.location.pathname;

  const handleStatusChange = event => {
    setStatus(event.target.value);
    if (isStatistics) {
      searchParams.set('status', event.target.value);
      history.push({ pathname, search: searchParams.toString() });
    }
  };

  return (
    <>
      {isLoading && (
        <Typography>
          <FillerSpinner />
          Loading Payment Statuses
        </Typography>
      )}
      {isError && (
        <Alert severity="error" style={{ padding: '4px', margin: '4px' }}>
          <AlertTitle>Error</AlertTitle>
          <Typography>An error occured during loading statuses</Typography>
        </Alert>
      )}
      {isSuccess && (
        <FormControl variant="outlined" style={{ width: '220px' }}>
          <InputLabel>Payment Status </InputLabel>
          <Select id="type" name="type" value={status} onChange={handleStatusChange}>
            {data.map(status => {
              const humanReadableStatus =
                status === 'CANCELLED_PAYMENT'
                  ? 'Cancelled'
                  : status === 'PAYMENT_SUCCEEDED'
                  ? 'Paid'
                  : status === 'TRANSACTION_STARTED'
                  ? 'In progress'
                  : status === 'REFUNDED'
                  ? 'Refunded'
                  : status === 'TIMEOUTED'
                  ? 'Timeouted'
                  : 'Unknown state';
              return (
                <MenuItem key={status} value={status}>
                  {humanReadableStatus}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default PaymentStatusSelect;
