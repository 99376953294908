import gql from 'graphql-tag';

import { TICKET_FRAGMENT } from '../tickets/graphql';
import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const FORM_FRAGMENT = gql`
  fragment FormFragment on Form {
    id
    name
    submissionsUrl
  }
`;

export const TICKET_TYPE_FRAGMENT = gql`
  fragment TicketTypeFragment on TicketType {
    id
    name
    forSale
    normalPrice
    normalPriceInHuf
    normalPriceInEur
    elbCardPrice
    elbCardPriceInHuf
    elbCardPriceInEur
    expirationDate
    limit
    ticketStats {
      active
      reserved
      sold
    }
    totalSalesInHuf
    totalSalesInEur
    totalSalesInHufSum
    totalSalesInEurSum
  }
`;

export const EVENT_STATS_FRAGMENT = gql`
  fragment EventStatsFragment on Event {
    ticketStats {
      active
      reserved
      sold
      limit
    }
    totalSalesInHuf
    totalSalesInEur
    totalSalesInHufSum
    totalSalesInEurSum
  }
`;

export const EVENT_FIELDS_FRAGMENT = gql`
  fragment EventFieldsFragment on Event {
    id
    name
    forSale
    from
    until
    ticketTypes {
      ...TicketTypeFragment
    }
    location
    ticketLimit
    createdAt
    createdBy {
      id
      fullName
    }
    billingAccount {
      id
      name
    }
    form {
      id
      submissionsUrl
    }
    isExternal
  }
  ${TICKET_TYPE_FRAGMENT}
`;

export const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    ...EventFieldsFragment
    ...EventStatsFragment
  }
  ${EVENT_FIELDS_FRAGMENT}
  ${EVENT_STATS_FRAGMENT}
`;

export const EVENT_WITHOUT_STATS_FRAGMENT = gql`
  fragment EventWithoutStatsFragment on Event {
    ...EventFieldsFragment
  }
  ${EVENT_FIELDS_FRAGMENT}
`;

/** Events */
export const GET_EVENTS = gql`
  query GetEvents($options: OptionsInput) {
    events(options: $options) @connection(key: "events") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...EventWithoutStatsFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${EVENT_WITHOUT_STATS_FRAGMENT}
`;

export const GET_EVENT = gql`
  query GetEvent($id: String!) {
    event(id: $id) {
      ...EventFragment
    }

    ticketsByEvent(eventId: $id) {
      ...TicketFragment
    }
  }
  ${EVENT_FRAGMENT}
  ${TICKET_FRAGMENT}
`;

export const GET_EVENT_FOR_PRODUCT = gql`
  query GetEvent($id: String!) {
    event(id: $id) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($data: CreateEventInput!) {
    createEvent(data: $data) {
      ...EventFragment
      startSales
    }
  }
  ${EVENT_FRAGMENT}
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($data: UpdateEventInput) {
    updateEvent(data: $data) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const START_SALE_FOR_EVENT = gql`
  mutation StartSaleForEvent($id: String!) {
    startSaleForEvent(id: $id) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const STOP_SALE_FOR_EVENT = gql`
  mutation StopSaleForEvent($id: String!) {
    stopSaleForEvent(id: $id) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

/** Ticket Types */
/** Events */
export const GET_TICKET_TYPES = gql`
  query GetTicketTypes($options: OptionsInput) {
    ticketTypes(options: $options) @connection(key: "ticketTypes") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...TicketTypeFragment
        event {
          name
        }
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${TICKET_TYPE_FRAGMENT}
`;

export const GET_TICKET_TYPE = gql`
  query GetTicketType($id: String!) {
    ticketType(id: $id) {
      ...TicketTypeFragment
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;

export const CREATE_TICKET_TYPE = gql`
  mutation CreateTicketType($data: CreateTicketTypeInput!) {
    createTicketType(data: $data) {
      ...TicketTypeFragment
      startSales
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;

export const UPDATE_TICKET_TYPE = gql`
  mutation UpdateTicketType($data: UpdateTicketTypeInput!) {
    updateTicketType(data: $data) {
      ...TicketTypeFragment
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;

export const START_SALE_FOR_TICKET_TYPE = gql`
  mutation StartSaleForTicketType($id: String!) {
    startSaleForTicketType(id: $id) {
      ...TicketTypeFragment
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;

export const STOP_SALE_FOR_TICKET_TYPE = gql`
  mutation StopSaleForTicketType($id: String!) {
    stopSaleForTicketType(id: $id) {
      ...TicketTypeFragment
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;

/** Forms */
export const GET_FORMS = gql`
  query GetForms {
    forms {
      ...FormFragment
    }
  }
  ${FORM_FRAGMENT}
`;

export const DELETE_EVENT_FORM = gql`
  mutation DeleteEventForm($data: DeleteEventFormInput!) {
    deleteEventForm(data: $data) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;
