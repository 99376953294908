import React from 'react';
import PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import { reportBalance as reportBalanceSchema } from '../../../../utils/validationSchemas';

import { useReportAccountBalance } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ReportBalanceFields } from '..';

function ReportTerminalBalance({ id, own = true, isTopToolbar = false }) {
  const dialogProps = {
    title: 'Report Account Balance',
    submitButtonText: 'Report Balance',
    fields: <ReportBalanceFields />,
  };

  const [reportAccountBalance] = useReportAccountBalance(id);

  const onSubmit = async values => reportAccountBalance(values, id)

  const formikProps = {
    initialValues: {
      amount: '',
    },
    validationSchema: reportBalanceSchema,
    onSubmit,
  };

  const snackbarProps = {
    successText: 'Report has been successful!',
  };

  const actionButtonProps = {
    variant: 'text',
    color: 'default',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Report Balance"
      actionButtonProps={actionButtonProps}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
      isTopToolbar={isTopToolbar}
    />
  );
}

ReportTerminalBalance.propTypes = {
  id: Types.id,
  own: PropTypes.bool,
};

export default ReportTerminalBalance;
