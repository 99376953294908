import React, { useState } from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { StartSaleForEvent, StopSaleForEvent, UpdateEvent, AddTicketType } from '..';
import CopyEvent from '../CopyEvent/CopyEvent';
import { Button } from '@material-ui/core';
import Link from '../../../lib/components/Link';
import { CreateProductDialog } from '../../../products/components';
import globalApplicationConfiguration from '../../../../config/config';
import CreateOrEditEventTicketType from '../../../event-ticket-types/CreateOrEditEventTicketType';
import UpdateExchangeRateDialog from './UpdateExchangeRateDialog';

function EventToolbar({ event }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const access = useCheckPrivilege(Privileges.UPDATE_EVENT);
  if (!access) return null;
  const featureFlagProductsDisplayCreateTicketProductOnEventPage =
    globalApplicationConfiguration.featureFlags.products.displayCreateTicketProductsOnEventPage;

  return (
    <Toolbar>
      <CopyEvent event={event} />
      {event.forSale ? (
        <StopSaleForEvent id={event.id} name={event.name} />
      ) : (
        <StartSaleForEvent id={event.id} />
      )}
      <UpdateEvent event={event} />
      <AddTicketType eventId={event.id} />

      <Button
        variant="contained"
        color="primary"
        style={{ margin: '8px' }}
        onClick={() => {
          handleOpen();
        }}
      >
        Create Event Ticket Type
      </Button>
      <CreateOrEditEventTicketType eventId={event.id} open={open} setOpen={setOpen} />

      {featureFlagProductsDisplayCreateTicketProductOnEventPage && (
        <CreateProductDialog eventId={event.id} initialType={'ticket'} />
      )}

      <UpdateExchangeRateDialog eventId={event.id} />

      <Button
        component={Link}
        to={`/event-checking?eventId=${event.id}&eventName=${encodeURIComponent(event.name)}`}
        variant="contained"
        color="primary"
      >
        Event checking
      </Button>
      <Button
        component={Link}
        to={`/event-checking-stats?eventId=${event.id}&eventName=${encodeURIComponent(event.name)}`}
        variant="contained"
        color="primary"
      >
        Checking stats
      </Button>
    </Toolbar>
  );
}

EventToolbar.propTypes = {
  event: Types.event.isRequired,
};

export default EventToolbar;
