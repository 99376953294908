import gql from 'graphql-tag';

import { TRANSACTION_FRAGMENT } from '../transactions/graphql';
import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    id
    balance
    balanceInHuf
    balanceInEur
    name
    active
    createdAt
    type
    createdBy {
      id
      fullName
    }
    sales {
      totalInHuf
      totalInHufSum
      todayInHuf
      todayInHufSum
      totalInEur
      totalInEurSum
      todayInEur
      todayInEurSum
    }
  }
`;

export const GET_ACCOUNT = gql`
  query GetAccount($id: String!) {
    account(id: $id) {
      ...AccountFragment
    }

    transactionsByAccount(accountId: $id, options: {}) {
      entries {
        ...TransactionFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TRANSACTION_FRAGMENT}
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($options: OptionsInput) {
    accounts(options: $options) @connection(key: "accounts") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...AccountFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($id: String!) {
    activateAccount(id: $id) {
      id
      active
    }
  }
`;

export const INACTIVATE_ACCOUNT = gql`
  mutation InactivateAccount($id: String!) {
    inactivateAccount(id: $id) {
      id
      active
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($data: CreateAccountInput!) {
    createAccount(data: $data) {
      ...AccountFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($data: UpdateAccountInput) {
    updateAccount(data: $data) {
      id
      name
    }
  }
`;
