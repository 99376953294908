import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { GET_TERMINAL } from '../../graphql';
import { Document, WithDocumentQuery } from '../../../common/components';
import TerminalToolbar from '../TerminalToolbar/TerminalToolbar';
import TerminalInfoCard from '../TerminalInfoCard/TerminalInfoCard';
import { AuditLogsTable } from '../../../audit/components';

function Terminal({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_TERMINAL}>
      {(terminal, accountAuditLogs) => (
        <Document
          toolbar={<TerminalToolbar terminal={terminal} />}
          infoCard={<TerminalInfoCard terminal={terminal} />}
          table={<AuditLogsTable inline auditLogs={accountAuditLogs.entries} />}
        />
      )}
    </WithDocumentQuery>
  );
}

Terminal.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Terminal;
