import React from 'react';

import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import RemoveProductFromOrder from '../RemoveProductFromOrder';

function OrderProductsTable(products) {
  return (
    <div>
      <Card
        display={'flex'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        alignItems={'center'}
        alignContent={'center'}
      >
        <CardContent>
          <Typography variant="h6" style={{ marginBottom: '8px' }}>
            Product Items
          </Typography>
          <TableContainer
            component={Paper}
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price (HUF)</TableCell>
                  <TableCell>Price (EUR)</TableCell>
                  <TableCell>Include in email</TableCell>
                  <TableCell>Membership</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.products?.length > 0 ? (
                  products.products.map(product => {
                    const {
                      productId,
                      name,
                      description,
                      priceInHuf,
                      priceInEur,
                      type,
                      imageUrl,
                      includedInOrderEmail,
                      isMembershipGranted,
                      membershipExpiresInDays,
                    } = product;

                    return (
                      <TableRow key={name}>
                        <TableCell>
                          {imageUrl ? (
                            <img src={imageUrl} width={'60px'} alt={`${name}'s named product`} />
                          ) : (
                            'No image'
                          )}
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{type}</TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>{`${priceInHuf} Ft`}</TableCell>
                        <TableCell>{`€ ${priceInEur}`}</TableCell>
                        <TableCell>{includedInOrderEmail ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          {isMembershipGranted
                            ? `Membership expires in ${membershipExpiresInDays} days`
                            : 'No'}
                        </TableCell>
                        <TableCell>
                          <RemoveProductFromOrder type={type} id={productId} />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography style={{ fontSize: '16px' }}>
                        Sorry, no matching records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}{' '}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
}

export default OrderProductsTable;
