import React, { useEffect, useState } from 'react';
import { Toolbar } from '../../../common/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import useUpdateCustomerEmail from '../../hooks/useUpdateCustomerEmail';
import { useSnackbar } from '../../../lib/hooks';

function CustomerToolbar({ customer, refetch }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(customer.email);
  const { mutate, isError, isSuccess } = useUpdateCustomerEmail(customer.id);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Customer Email updated', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      handleClose();
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Cannot update customer email!', 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  return (
    <Toolbar>
      <Button onClick={handleOpen} variant="outlined" color="primary">
        Update Email
      </Button>
      <Dialog open={open}>
        <DialogTitle>Update Customer Email</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            style={{ margin: '8px 0px', alignSelf: 'center', width: '100%' }}
            id="newEmailAddress"
            name="newEmailAddress"
            label="New Email Address"
            type="text"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({ newEmailAddress: email });
              }}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Toolbar>
  );
}

export default CustomerToolbar;
