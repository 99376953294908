import format from 'date-fns/format';
import { isEmpty } from 'lodash';

import { msToDuration } from './helpers';

export const DATE_FORMAT = 'y/MM/dd';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export function formatForint(money) {
  return new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(money);
}

export function formatEuro(money) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency', currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(money);
}

export function formatDate(dateTime) {
  const parsedDateTime = new Date(dateTime);

  if (parsedDateTime === 'Invalid Date') {
    throw new Error('Invalid Date');
  }

  return format(parsedDateTime, DATE_FORMAT);
}

export function formatDateTime(dateTime) {
  const parsedDateTime = new Date(dateTime);

  if (parsedDateTime === 'Invalid Date') {
    throw new Error('Invalid Date');
  }

  return format(parsedDateTime, DATE_TIME_FORMAT);
}

const DURATION_OPTIONS = {
  hours: 0,
  minutes: 1,
  seconds: 2,
  milliseconds: 3,
};
export function formatDuration(
  duration,
  options = {
    hours: true,
    minutes: true,
    seconds: true,
    milliseconds: true,
  },
) {
  if (isEmpty(options)) {
    throw new Error('Format options are undefined!');
  }
  const keys = Object.keys(options)
    .map(k => DURATION_OPTIONS[k])
    .sort((a, b) => a - b);
  return msToDuration(duration)
    .slice(keys[0], keys[keys.length - 1] + 1)
    .map(t => (t < 10 ? `0${t}` : t))
    .join(':');
}
