import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useLatestExchangeRate() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['latest-exchange-rate'],
        queryFn: async () => {
            const response = await axios.get(`${REACT_APP_SERVER_URL}exchange-rates/latest`);
            return response?.data;
        }
    });
}