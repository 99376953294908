import { useMutation } from '@apollo/react-hooks';

import Privileges from '../../../utils/access-control/privileges.json';

import { REPORT_BALANCE_FOR_TERMINAL_ACCOUNT } from '../graphql';

import { useCheckPrivileges } from '../../lib/hooks';
import { GET_PROFILE } from '../../users/graphql';
import { GET_TERMINAL } from '../../terminals/graphql';

export default function useReportTerminalBalance(id) {
  const [anyAuditLogAccess, anyOrderAccess] = useCheckPrivileges(
    Privileges.READ_ANY_AUDIT_LOG,
    Privileges.READ_ANY_ORDER,
  );
  const query = {
    query: anyAuditLogAccess && anyOrderAccess ? GET_TERMINAL : GET_PROFILE,
    variables: { id },
  };

  const [reportBalanceForTerminalAccount, result] = useMutation(
    REPORT_BALANCE_FOR_TERMINAL_ACCOUNT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [query],
    },
  );

  async function simpleReportBalanceForTerminalAccount(values, id) {
    await reportBalanceForTerminalAccount({ variables: { data: { ...values, id } } });
  }

  return [simpleReportBalanceForTerminalAccount, result];
}
