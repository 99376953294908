import React from 'react';

import { InfoCard, InfoCardRow } from '../../../common/components';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';
import * as Types from '../../../../utils/propTypes';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

function AccountInfoCard({ account }) {
  const rows = [
    {
      label: 'Created by',
      value: account.createdBy !== null ? account.createdBy.fullName : '',
    },
    {
      label: 'Created at',
      value: formatDateTime(account.createdAt),
    },
    {
      label: 'Balance (HUF)',
      value:
        account.balance > 0
          ? formatForint(Math.round(account?.balance / 10) * 10)
          : account?.balanceInHuf > 0
          ? formatForint(Math.round(account?.balanceInHuf / 10) * 10)
          : '-',
    },
    {
      label: 'Balance (EUR)',
      value: account.balanceInEur ? formatEuro(account?.balanceInEur) : '-',
    },
    {
      label: 'Status',
      value: account.active ? 'Active' : 'Inactive',
    },
    {
      label: 'Sales Today made in HUF',
      value: formatForint(Math.round(account.sales.todayInHuf / 10) * 10),
    },
    {
      label: 'Sales Today made in EUR',
      value: formatEuro(account.sales.todayInEur),
    },
    {
      label: 'Total Sales Today in HUF',
      value: formatForint(Math.round(account.sales.todayInHufSum / 10) * 10),
    },
    {
      label: 'Total Sales Today in EUR',
      value: formatEuro(account.sales.todayInEurSum),
    },
    {
      label: 'Sales made in HUF',
      value: formatForint(Math.round(account.sales.totalInHuf / 10) * 10),
    },
    {
      label: 'Sales made in EUR',
      value: formatEuro(account.sales.totalInEur),
    },
    {
      label: 'Total Sales in HUF',
      value: formatForint(Math.round(account.sales.totalInHufSum / 10) * 10),
    },
    {
      label: 'Total Sales in EUR',
      value: formatEuro(account.sales.totalInEurSum),
    },
    {
      label: 'Total Error',
      value: 'TBD',
    },
  ];
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {account.name}
        </Typography>
        {rows.map(({ label, value }) => (
          <Grid item key={label} style={{ margin: '8px' }}>
            <InfoCardRow label={label} value={value} />
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
  return <InfoCard title={account.name} rows={rows} />;
}

AccountInfoCard.propTypes = {
  account: Types.account.isRequired,
};

export default AccountInfoCard;
