import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const AUDIT_LOG_FRAGMENT = gql`
  fragment AuditLogFragment on AccountAuditLog {
    id
    prettyId
    account {
      id
      name
      active
      type
    }
    balance {
      expected
      reported
      difference
    }
    status
    handledBy {
      id
      fullName
    }
    handledAt
    createdBy {
      id
      fullName
    }
    terminalName {
      id
      name
    }
    createdAt
    reportedBy {
      id
      fullName
    }
    auditLogSeqId
    auditEntityType
  }
`;

export const GET_AUDIT_LOGS = gql`
  query GetAuditLogs($options: OptionsInput) {
    accountAuditLogs(options: $options) @connection(key: "accountAuditLogs") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...AuditLogFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${AUDIT_LOG_FRAGMENT}
`;

export const GET_AUDIT_LOG = gql`
  query GetAuditLog($id: String!) {
    accountAuditLog(id: $id) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const REPORT_BALANCE = gql`
  mutation ReportBalance($data: CreateAccountAuditLogInput) {
    createAccountAuditLog(data: $data) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const REPORT_BALANCE_FOR_USER = gql`
  mutation ReportBalanceForUser($data: CreateAccountAuditLogForUserInput) {
    createAccountAuditLogForUser(data: $data) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const REPORT_BALANCE_FOR_TERMINAL_ACCOUNT = gql`
  mutation ReportBalanceForTerminalAccount($data: CreateAccountAuditLogForUserInput) {
    createTerminalAccountAuditLog(data: $data) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const ACCEPT_AUDIT_LOG = gql`
  mutation AcceptAuditLog($id: String!) {
    acceptAccountAuditLog(id: $id) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const REJECT_AUDIT_LOG = gql`
  mutation RejectAuditLog($id: String!) {
    denyAccountAuditLog(id: $id) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;
