import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { Table, CollectionTable } from '../../../common/components';
import { RemoveTicketFromOrder, UpdateOrderTicket } from '..';

function OrderTickets({ tickets }) {
  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        empty: true,
        renderCell: (_, { id }) => (
          <>
            <RemoveTicketFromOrder id={id} />
            <UpdateOrderTicket id={id} />
          </>
        ),
      },
    },
    {
      name: 'event.name',
      label: 'Event',
      options: {
        renderCell: (value, ticket) => routes.event.createLink(ticket.event.id, value),
        filterType: 'textField',
      },
    },
    {
      name: 'ticketType.name',
      label: 'Ticket Type',
      options: {
        renderCell: (value, { ticketType, event }) =>
          routes.ticketType.createLink(ticketType.id, value, event.id, event.name),
        filterType: 'textField',
      },
    },
    {
      name: 'priceInHuf',
      label: 'Price (HUF)',
      type: 'currency',
    },
    {
      name: 'priceInEur',
      label: 'Price (EUR)',
      type: 'currency_eur',
    },
    {
      name: 'customer.fullName',
      label: 'Customer',
      options: {
        renderCell: (value, { customer }) =>
          customer ? routes.customer.createLink(customer.id, value) : null,
        filterType: 'textField',
      },
    },
    {
      name: 'customer.email',
      label: 'Customer Email',
      options: {
        renderCell: (value, { customer }) =>
          customer ? routes.customer.createLink(customer.id, customer.fullName, value) : null,
        filterType: 'textField',
      },
    },
    {
      name: 'elbCardNumber',
      label: 'ELB Card Number',
      // There is no embedded ELB Card yet.
      // options: {
      //   renderCell: (value, { elbCard }) =>
      //     value ? routes.elbCard.createLink(elbCard.id, value) : null,
      // },
    },
    {
      name: 'ticketNumber',
      label: 'Ticket Number',
      options: {
        renderCell: (_value, ticket) => {
          const { ticketNumber2ndGen, ticketNumber } = ticket;
          return ticketNumber2ndGen || ticketNumber;
        }
      },
    },
  ];

  return (
    <CollectionTable>
      <Table title="Tickets" data={tickets} columns={columns} />
    </CollectionTable>
  );
}

OrderTickets.propTypes = {
  tickets: PropTypes.arrayOf(Types.ticket),
};

export default OrderTickets;
