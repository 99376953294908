import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useCreateCoupons from './useCreateCoupons.hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from '../lib/hooks';

function CreateCoupon() {
  const { mutate, isSuccess, isError } = useCreateCoupons();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      enqueueSnackbar('Succesfully created coupon!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Couldn't create coupon!", 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      isActive: true,
      discount: '',
      discountType: '',
      code: '',
      expiresAt: null,
    },
    validationSchema: yup.object({
      name: yup.string().required('Coupon name is required'),
      description: yup.string().optional(),
      isActive: yup.boolean().required('Check if coupon is active'),
      discount: yup
        .number()
        .required('Enter the amount of the coupon')
        .positive(),
      discountType: yup.string().required('Select the type of the coupon!'),
      code: yup.string().required('Enter the coupon code'),
      expiresAt: yup
        .date()
        .nullable()
        .optional(),
    }),
    onSubmit: async (values, actions) => {
      mutate(values);
    },
  });

  return (
    <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px' }}>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Coupon
      </Button>
      <Dialog open={open}>
        <DialogTitle>Create Coupon</DialogTitle>
        <DialogContent>
          <form>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                required
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                id="name"
                name="name"
                label="Coupon Name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                id="description"
                name="description"
                label="Coupon Description"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
              <TextField
                required
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                id="discount"
                name="discount"
                label="Coupon Discount"
                type="number"
                value={formik.values.discount}
                onChange={formik.handleChange}
                error={formik.touched.discount && Boolean(formik.errors.discount)}
                helperText={formik.touched.discount && formik.errors.discount}
              />
              <FormControl
                variant="outlined"
                style={{
                  width: '256px',
                  display: 'inline-flex',
                  alignSelf: 'center',
                  margin: '8px 0px',
                }}
              >
                <InputLabel>Coupon Discount Type*</InputLabel>
                <Select
                  id="discountType"
                  name="discountType"
                  value={formik.values.discountType}
                  onChange={formik.handleChange}
                  error={formik.touched.discountType && Boolean(formik.errors.discountType)}
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value={'FIXED_AMOUNT'}>Fixed amount</MenuItem>
                  <MenuItem value={'PERCENTAGE'}>Percentage (%)</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                id="code"
                name="code"
                label="Coupon Code"
                type="text"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
              <KeyboardDatePicker
                variant="outlined"
                format="yyyy-MM-dd"
                margin="normal"
                id="expiresAt"
                name="expiresAt"
                label="Expiration date"
                placeholder="YYYY-MM-DD"
                value={formik.values.expiresAt}
                onChange={value => {
                  formik.setFieldValue('expiresAt', value, true);
                }}
                style={{ margin: '3px', minWidth: '250px' }}
                autoOk={true}
              />
              <FormControlLabel
                style={{ alignSelf: 'center', textAlign: 'left' }}
                control={
                  <Checkbox
                    checked={formik.values.isActive}
                    value={formik.values.isActive}
                    onChange={formik.handleChange}
                    name="isActive"
                    id="isActive"
                    color="primary"
                  />
                }
                label="Is the coupon active?"
              />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={formik.isValid && !formik.dirty}
            onClick={formik.handleSubmit}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateCoupon;
