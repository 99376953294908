import React, { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useCreateTicketType, useUpdateTicketType } from '../../hooks';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { MAX_INTEGER } from '../../../../utils/validationSchemas';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useLatestExchangeRate from '../../../exchangeRate/useLatestExchangeRate';
import { useSnackbar } from '../../../lib/hooks';
function MutateTicketTypeWithCurrency({ eventId, ticketType = {} }) {
  const edit = !isEmpty(ticketType);
  const {
    id,
    name,
    expirationDate,
    limit,
    normalPriceInHuf,
    normalPriceInEur,
    elbCardPriceInHuf,
    elbCardPriceInEur,
    startSales,
  } = ticketType;

  const [create, resultCreate] = useCreateTicketType(eventId);
  const { enqueueSnackbar } = useSnackbar();
  const [update, resultUpdate] = useUpdateTicketType(id);

  const [inEventLimit, setInEventLimit] = useState(true);
  const savedLimitValue = limit?.toString() || '';
  const [ticketTypeLimit, setTicketTypeLimit] = useState(savedLimitValue);

  useEffect(() => {
    setInEventLimit(true);
    setTicketTypeLimit(savedLimitValue);
  }, []);

  useEffect(() => {
    if (inEventLimit === false) {
      setTicketTypeLimit('');
    }
  }, [inEventLimit]);

  const { data: latestExchangeRate } = useLatestExchangeRate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const limitAndPriceValidation = yup
    .number()
    .max(MAX_INTEGER)
    .min(0);
  const formik = useFormik({
    initialValues: {
      name: name || '',
      expirationDate: expirationDate || null,
      limit: limit || '',
      normalPriceInHuf: normalPriceInHuf || '',
      normalPriceInEur: normalPriceInEur || '',
      elbCardPriceInHuf: elbCardPriceInHuf || '',
      elbCardPriceInEur: elbCardPriceInEur || '',
      startSales: edit ? null : startSales || true,
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required!'),
      expirationDate: yup
        .date('Please provide a valid date!')
        .nullable()
        .default(null),
      limit: yup
        .number()
        .integer()
        .min(0),
      normalPriceInHuf: limitAndPriceValidation.test(
        'atLeastOne',
        'Neither normalPriceInHuf or normalPriceInEur is set!',
        function atLeastOne(normalPriceInHuf) {
          return !(isNil(this.parent.normalPriceInEur) && isNil(normalPriceInHuf));
        },
      ),
      normalPriceInEur: limitAndPriceValidation.test(
        'atLeastOne',
        'Neither normalPriceInHuf or normalPriceInEur is set!',
        function atLeastOne(normalPriceInEur) {
          return !(isNil(this.parent.normalPriceInHuf) && isNil(normalPriceInEur));
        },
      ),
      elbCardPriceInHuf: limitAndPriceValidation.optional(),
      elbCardPriceInEur: limitAndPriceValidation.optional(),
    }),
    onSubmit: edit
      ? async values => {
          const transformedValues = {
            ...values,
            limit: ticketTypeLimit === '' ? limit : parseInt(ticketTypeLimit),
          };
          update(transformedValues);
        }
      : async values => {
          const transformedValues = {
            ...values,
            limit: ticketTypeLimit === '' ? 0 : parseInt(ticketTypeLimit),
          };
          create(transformedValues);
        },
  });

  useEffect(() => {
    if (
      (resultCreate?.data && resultCreate.called) ||
      (resultUpdate?.data && resultUpdate.called)
    ) {
      handleClose();
      enqueueSnackbar(
        edit ? 'Ticket type has been edited!' : 'Ticket type has been created!',
        'success',
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      );
    }
    if (resultCreate.error || resultUpdate.error) {
      enqueueSnackbar(
        edit ? 'Ticket type could not been edited!' : 'Ticket type could been created!',
        'error',
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      );
    }
  }, [resultCreate, resultUpdate]);

  const handleChange = event => setInEventLimit(event.target.checked);
  const handleLimitChange = event => setTicketTypeLimit(event.target.value);

  const calculateHufFromEur = eurPrice => {
    const hufPrice = parseFloat((eurPrice * latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('normalPriceInHuf', hufPrice);
  };

  const calculateEurFromHuf = hufPrice => {
    const eurPrice = parseFloat((hufPrice / latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('normalPriceInEur', eurPrice);
  };

  const calculateHufFromEurForElbPrice = eurPrice => {
    const hufPrice = parseFloat((eurPrice * latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('elbCardPriceInHuf', hufPrice);
  };

  const calculateEurFromHufForElbPrice = hufPrice => {
    const eurPrice = parseFloat((hufPrice / latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('elbCardPriceInEur', eurPrice);
  };

  return (
    <Box>
      <Button
        style={{ marginLeft: '4px' }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        {edit ? 'Edit Ticket Type' : 'Add Ticket Type'}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>{edit ? 'Edit Ticket Type' : 'Add Ticket Type'}</DialogTitle>
        <DialogContent>
          <form>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                name="name"
                id="name"
                type="text"
                required
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <KeyboardDatePicker
                inputVariant="outlined"
                format="yyyy-MM-dd"
                margin="normal"
                name="expirationDate"
                autoOk={true}
                placeholder="YYYY-MM-DD"
                disablePast
                clearable
                id="expirationDate"
                label="Expiration Date"
                variant="outlined"
                fullWidth
                value={formik.values.expirationDate}
                onChange={value => {
                  formik.setFieldValue('expirationDate', value);
                }}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={inEventLimit}
                    onChange={handleChange}
                    name="includeInEventLimit"
                  />
                }
                label="Include ticket type limit in event limit?"
              />

              {inEventLimit === true && (
                <TextField
                  name="limit"
                  id="limit"
                  type="number"
                  label="Limit"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={ticketTypeLimit}
                  onChange={handleLimitChange}
                />
              )}

              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Global exchange rate: {latestExchangeRate?.exchangeRate} HUF/EUR
              </Typography>

              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="normalPriceInHuf"
                  name="normalPriceInHuf"
                  label="Price (HUF)"
                  type="number"
                  min="0"
                  value={formik.values.normalPriceInHuf}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateEurFromHuf(formik.values.normalPriceInHuf)}
                disabled={!formik.values.normalPriceInHuf}
              >
                Calculate EUR from HUF input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="normalPriceInEur"
                  name="normalPriceInEur"
                  label="Price (EUR)"
                  type="number"
                  min="0"
                  fullWidth
                  value={formik.values.normalPriceInEur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateHufFromEur(formik.values.normalPriceInEur)}
                disabled={!formik.values.normalPriceInEur}
              >
                Calculate HUF from EUR input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="elbCardPriceInHuf"
                  name="elbCardPriceInHuf"
                  label="ELB Card price (HUF)"
                  type="number"
                  min="0"
                  fullWidth
                  value={formik.values.elbCardPriceInHuf}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateEurFromHufForElbPrice(formik.values.elbCardPriceInHuf)}
                disabled={!formik.values.elbCardPriceInHuf}
              >
                Calculate EUR from HUF input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="elbCardPriceInEur"
                  name="elbCardPriceInEur"
                  label="ELB Card price (EUR)"
                  type="number"
                  min="0"
                  value={formik.values.elbCardPriceInEur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '4px' }}
                onClick={() => calculateHufFromEurForElbPrice(formik.values.elbCardPriceInEur)}
                disabled={!formik.values.elbCardPriceInEur}
              >
                Calculate HUF from EUR input field
              </Button>
              {!edit ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.startSales}
                      onChange={formik.handleChange}
                      name="startSales"
                    />
                  }
                  label="Start Sales"
                />
              ) : null}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
            {edit ? 'Edit Ticket Type' : 'Add Ticket Type'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MutateTicketTypeWithCurrency;
