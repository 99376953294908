import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';

import { InfoCardRow } from '../../../common/components';
import { OrderTimer, OrderChangeCalculator } from '..';

function OrderItemsInfoCard({
  order,
  paidAmounts,
  setPaidAmounts,
  terminal,
  setTerminal,
  paymentMode,
  setPaymentMode,
  currencyOption,
  setCurrencyOption,
  exchangeRate,
}) {
  const { tickets, elbCards, expiresAt } = order;

  const rows = [
    {
      label: 'Number of Tickets',
      value: tickets.length,
    },
    {
      label: 'Number of ELB Cards',
      value: elbCards.length,
    },
    {
      label: 'TBD Manual Billing',
      value: 0,
    },
  ];

  return (
    <Card>
      <CardContent>
        <OrderTimer expiresAt={expiresAt} />

        <OrderChangeCalculator
          order={order}
          paidAmounts={paidAmounts}
          setPaidAmounts={setPaidAmounts}
          terminal={terminal}
          setTerminal={setTerminal}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          currencyOption={currencyOption}
          setCurrencyOption={setCurrencyOption}
          exchangeRate={exchangeRate}
        />

        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
          {rows.map(({ label, value }) => (
            <Grid item key={label}>
              <InfoCardRow label={label} value={value} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

OrderItemsInfoCard.propTypes = {
  order: Types.order.isRequired,
};

export default OrderItemsInfoCard;
