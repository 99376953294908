import React, { useEffect, useState } from 'react';
import useExchangeRates from './useExchangeRates';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';

function ExchangeRate() {
  const [limit, setLimit] = useState();
  const { data, isSuccess, refetch } = useExchangeRates(limit);
  const [xAxis, setXAxis] = useState([]);
  const [dataSet, setDataSet] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      console.log({ data });
      const xAxisData = data.map(data => {
        return format(new Date(data.date), 'yyyy-MM-dd');
      });
      setXAxis(xAxisData);

      const dataSetData = data.map(data => {
        return data.exchangeRate;
      });
      setDataSet(dataSetData);
    }
  }, [isSuccess, data, setXAxis, setDataSet]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
        <TextField
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          id="limit"
          name="limit"
          label="Limit"
          type="text"
          value={limit}
          onChange={e => {
            setLimit(e.target.value);
          }}
        />
        <Button variant="outlined" color="primary" onClick={refetch}>
          Set Limit
        </Button>
      </Grid>
      {isSuccess && (
        <Box style={{ width: '70vw' }}>
          <Line
            data={{
              labels: xAxis,
              datasets: [
                {
                  label: 'Exchange Rate',
                  data: dataSet,
                  borderColor: '#2A4D8F',
                  borderWidth: 2,
                  fill: false,
                },
              ],
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ExchangeRate;
