import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PAYMENTS_API_BASE_URL, PAYMENTS_API_ENDPOINTS } from '../payments.constants';

export const useGetPaymentsStatisticsTableData = (values) => {
    return useQuery({
        queryKey: ['payment-statistics-table', values],
        queryFn: async () => {
            const { from, to, status } = values
            console.log({ values });
            const response = await axios.get(
                `${PAYMENTS_API_BASE_URL}/${PAYMENTS_API_ENDPOINTS.PAYMENTS_CONTROLLER_BASE_URL}/statistics/table?from=${from}&to=${to}&status=${status}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
