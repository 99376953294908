import { useQuery } from '@tanstack/react-query';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../products.constants';

export default function useGetProductRevisionsById(id) {
    return useQuery({
        queryKey: ['product-revision-by-id'],
        queryFn: async () => {
            const token = localStorage.getItem('token')
            const { data } = await PRODUCTS_API_AXIOS_INSTANCE.get(`${PRODUCTS_API_ENDPOINTS.PRODUCTS_CONTROLLER_BASE_URL}/${id}/revisions`, { headers: { Authorization: token } })
            return data;
        },
        refetchOnWindowFocus: false,
    });
}