import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetDownloadV2TicketToken = () => {
    return useMutation({
        mutationKey: ['download-v2-ticket-token',],
        mutationFn: async (id) => {
            console.log({ id });
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}get-download-token/${id}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
