import React from 'react';

import { InfoCard, InfoCardRow } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import { formatForint, formatDate, formatEuro } from '../../../../utils/formatters';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

function TicketTypeInfoCard({
  ticketType: {
    name,
    forSale,
    normalPrice,
    normalPriceInHuf,
    normalPriceInEur,
    elbCardPrice,
    elbCardPriceInHuf,
    elbCardPriceInEur,
    expirationDate,
    limit,
    ticketStats,
    totalSalesInHuf,
    totalSalesInEur,
    totalSalesInHufSum,
    totalSalesInEurSum,
  },
}) {
  const rows = [
    {
      label: 'For Sale',
      value: forSale ? 'Yes' : 'No',
    },
    {
      label: 'Expiration Date',
      value: expirationDate ? formatDate(expirationDate) : '',
    },
    {
      label: 'Price (HUF)',
      value: normalPrice
        ? formatForint(Math.round(normalPrice / 10) * 10)
        : normalPriceInHuf
        ? formatForint(Math.round(normalPriceInHuf / 10) * 10)
        : '-',
    },
    {
      label: 'Price (EUR)',
      value: normalPriceInEur ? formatEuro(normalPriceInEur) : '-',
    },
    {
      label: 'Price with ELB Card (HUF)',
      value: elbCardPrice
        ? formatForint(Math.round(elbCardPrice / 10) * 10)
        : elbCardPriceInHuf
        ? formatForint(Math.round(elbCardPriceInHuf / 10) * 10)
        : '-',
    },
    {
      label: 'Price with ELB Card (EUR)',
      value: elbCardPriceInEur ? formatEuro(elbCardPriceInEur) : '-',
    },
    {
      label: 'Active',
      value: ticketStats.active,
    },
    {
      label: 'Reserved',
      value: ticketStats.reserved,
    },
    {
      label: 'Limit',
      value: limit || '∞',
    },
    {
      label: 'Sold',
      value: ticketStats.sold,
    },
    {
      label: 'Sales made in HUF',
      value: formatForint(Math.round(totalSalesInHuf / 10) * 10),
    },
    {
      label: 'Sales made in EUR',
      value: formatEuro(totalSalesInEur),
    },
    {
      label: 'Total Sales in HUF',
      value: formatForint(Math.round(totalSalesInHufSum / 10) * 10),
    },
    {
      label: 'Total Sales in EUR',
      value: formatEuro(totalSalesInEurSum),
    },
  ];
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {name}
        </Typography>
        {rows.map(({ label, value }) => (
          <Grid item key={label} style={{ margin: '8px' }}>
            <InfoCardRow label={label} value={value} />
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
  return <InfoCard title={name} rows={rows} />;
}

TicketTypeInfoCard.propTypes = {
  ticketType: Types.ticketType.isRequired,
};

export default TicketTypeInfoCard;
