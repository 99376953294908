import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useRenameEventTicketTypes(id) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['rename-event-ticket-types'],
        mutationFn: async (payload) => {
            console.log({ payload, id });
            const response = await axios.patch(`${REACT_APP_SERVER_URL}event-ticket-types/${id}`, payload);
            console.log(response?.data);
            return response?.data;
        },
        refetchOnWindowFocus: false,
        onSuccess: () => Promise(
            queryClient.invalidateQueries(['event-ticket-types']),
        )
    });
}