import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import useCoupons from './useCoupons.hooks';
import { differenceInDays, format, isFuture } from 'date-fns';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FillerSpinner } from '../lib/components';
import CouponsCalculator from './CouponsCalculator.component';
import CreateCoupon from './CreateCoupon.component';
import routes from '../../utils/routes';

export const getHumanReadableDiscountType = discountType => {
  switch (discountType) {
    case 'PERCENTAGE':
      return 'Percentage (%)';
    case 'FIXED_AMOUNT':
      return 'Fixed amount';
    default:
      return 'Unknown discount type';
  }
};

function CouponsPage() {
  const { data: coupons, isError, isSuccess, isLoading } = useCoupons();

  console.log('CouponsPage', { coupons, isError, isSuccess, isLoading });

  return (
    <Grid>
      <Typography variant="h3" style={{ margin: '12px' }}>
        Coupons
      </Typography>
      {isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <Typography>Something went wrong!</Typography>
        </Alert>
      )}
      <CreateCoupon />
      {isLoading && <FillerSpinner />}
      {isSuccess && (
        <Grid>
          <CouponsCalculator coupons={coupons} />
          <TableContainer
            component={Paper}
            style={
              {
                /*display: 'flex',*/
                /*justifyContent: 'center',*/
              }
            }
          >
            <Table
              style={{
                minWidth: '650px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Discount type</TableCell>
                  <TableCell>Discount value</TableCell>
                  <TableCell>Expires at</TableCell>
                  <TableCell>Activated?</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons?.length > 0 ? (
                  coupons.map(coupon => {
                    const {
                      _id,
                      name,
                      isActive,
                      discount,
                      discountType,
                      code,
                      expiresAt,
                      createdBy,
                    } = coupon;

                    const { firstName, lastName } = createdBy.profile;
                    const fullNameLink = routes.user.createLink({
                      id: createdBy.id,
                      fullName: `${firstName} ${lastName}`,
                    });

                    let discountDisplayValue = discount;
                    if (discountType === 'PERCENTAGE') {
                      discountDisplayValue += '%';
                    } else if (discountType === 'FIXED_AMOUNT') {
                      discountDisplayValue += ' HUF';
                    }

                    const hasExpired = expiresAt ? !isFuture(new Date(expiresAt)) : false;

                    let status = 'Unknown';
                    if (hasExpired) {
                      status = 'Expired';
                    } else if (isActive === true) {
                      status = 'Active';
                    } else if (isActive === false) {
                      status = 'Inactive';
                    }

                    let expiresInXDays = null;
                    if (status === 'Active' && expiresAt) {
                      const today = new Date();
                      expiresInXDays = differenceInDays(new Date(expiresAt), today);
                    }

                    console.log({ createdBy });

                    return (
                      <TableRow key={_id}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{code}</TableCell>
                        <TableCell>{getHumanReadableDiscountType(discountType)}</TableCell>
                        <TableCell>{discountDisplayValue}</TableCell>
                        <TableCell>
                          {expiresAt
                            ? format(new Date(expiresAt), 'yyyy-MM-dd HH:mm:ss')
                            : 'No expiration date'}{' '}
                          {expiresInXDays && `(Expires in ${expiresInXDays} days)`}
                        </TableCell>
                        <TableCell style={{ textAlign: 'left' }}>
                          {isActive ? (
                            <CheckCircleIcon htmlColor="green" style={{ fontSize: 24 }} />
                          ) : (
                            <CancelIcon htmlColor="red" style={{ fontSize: 24 }} />
                          )}
                        </TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{fullNameLink}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Alert severity="warning">
                        <AlertTitle>Coupons</AlertTitle>
                        <Typography style={{ fontSize: '16px' }}>There are no coupons.</Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}

CouponsPage.propTypes = {};

export default CouponsPage;
