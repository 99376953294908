import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';

function TicketInfoCard({
  ticket: { ticketNumber, customer, elbCard, event, ticketType, status, soldBy, soldAt },
}) {
  const elbCardRows = elbCard
    ? [
        {
          label: 'ELB Card',
          value: routes.elbCard.createLink(elbCard.id, elbCard.cardNumber),
        },
        // {
        //   label: 'ELB Card Type',
        //   value: routes.elbCardType.createLink(elbCard.type.id, elbCard.type.name),
        // },
      ]
    : [];
  const priceInHufRow = {
    label: 'Price (HUF)',
    value: elbCard
      ? formatForint(Math.round(ticketType.elbCardPrice / 10) * 10) ||
        formatForint(Math.round(ticketType.elbCardPriceInHuf / 10) * 10)
      : formatForint(Math.round(ticketType.normalPrice / 10) * 10) ||
        formatForint(Math.round(ticketType.normalPriceInHuf / 10) * 10),
  };
  const priceInEurRow = {
    label: 'Price (EUR)',
    value: elbCard
      ? formatEuro(ticketType.elbCardPriceInEur)
      : formatEuro(ticketType.normalPriceInEur),
  };

  const rows = [
    {
      label: 'Type',
      value: routes.ticketType.createLink(ticketType.id, ticketType.name, event.id, event.name),
    },
    {
      label: 'Customer',
      value: routes.customer.createLink(customer.id, customer.fullName),
    },
    {
      label: 'Event',
      value: routes.event.createLink(event.id, event.name),
    },
    ...elbCardRows,
    priceInHufRow,
    priceInEurRow,
    {
      label: 'Status',
      value: status,
    },
    {
      label: 'Sold at',
      value: formatDateTime(soldAt),
    },
    {
      label: 'Sold by',
      value: soldBy ? routes.user.createLink(soldBy) : '',
    },
  ];
  return <InfoCard title={ticketNumber} rows={rows} />;
}

TicketInfoCard.propTypes = {
  ticket: Types.ticket.isRequired,
};

export default TicketInfoCard;
