import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetNotes(id, relatedField) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['notes'],
        queryFn: async () => {
            if (relatedField === undefined) {
                const response = await axios.get(`${REACT_APP_SERVER_URL}notes`);
                return response?.data;
            } else if (relatedField === 'order') {
                const response = await axios.get(`${REACT_APP_SERVER_URL}orders/${id}/notes`);
                return response?.data;
            } else if (relatedField === 'customer') {
                const response = await axios.get(`${REACT_APP_SERVER_URL}customers/${id}/notes`);
                return response?.data;
            } else if (relatedField === 'elb-card') {
                const response = await axios.get(`${REACT_APP_SERVER_URL}elb-cards/${id}/notes`);
                return response?.data;
            } else {
                return null
            }
        },
        refetchOnWindowFocus: false,
    });
}