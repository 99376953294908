import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { useDialog } from '../../../lib/hooks';
import { useCreateCustomer, useSearchCustomers } from '../../hooks';

import { DialogForm } from '../../../common/components';
import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';
import { CustomerAutocompleteInfo } from '..';
import routes from '../../../../utils/routes';
import { useHistory } from 'react-router-dom';
import AutocompleteActiveOnlineCardInfo from '../CustomerAutocompleteInfo/AutocompleteActiveOnlineCardInfo';

const useStyles = makeStyles(() => ({
  addButton: {
    padding: 0,
  },
}));

function transformCustomers(data) {
  const {
    searchCustomers: { entries },
  } = data;
  return entries.filter(({ deleted }) => !deleted);
}

function FormikCustomerAutocomplete({ name, InputProps = {}, toggleElbCard = false, isCustomersTable = false, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const [, setFieldText] = useState("");
  const debouncedSetFieldText = debounce((value, reason) => {
    if (value && value !== "undefined") {
      setFieldText(value);
    }
    if (reason === "input") {
      setSearchKeyword(value);
    }
    else if (reason === "reset" && isCustomersTable === true) {
      onChangeHandler(value);
    }
  }, 400);
  const onInputChange = useCallback(
    (_, value, reason) => {
      debouncedSetFieldText(value, reason);
    },
    [debouncedSetFieldText],
  );

  const [searchKeyword, setSearchKeyword] = useState();
  const { refetch: searchCustomers, loading, isLoading, data } = useSearchCustomers(searchKeyword, isCustomersTable === true);
  useEffect(() => {
    if (searchKeyword) {
      searchCustomers(searchKeyword);
    }
  }, [searchKeyword, searchCustomers])

  const customers = useMemo(() => (data ? transformCustomers(data) : []), [data]);

  const {
    values: { [name]: customer },
    setFieldValue,
  } = useFormikContext();

  const [, formikProps, dialogProps, snackbarProps] = useCreateCustomer();
  const extendedFormikProps = {
    ...formikProps,
    onSuccess: ({ data: { createCustomer } }) => setFieldValue(name, createCustomer),
  };
  const [open, handleOpen, handleClose] = useDialog(false);

  const sort = useCallback(
    (a, b) => -b.fullName?.toLowerCase?.()?.localeCompare(a.fullName?.toLowerCase?.()),
    [],
  );
  const options = useMemo(() => customers.sort(sort), [customers, sort]);
  // label example: {Art Vandelay / }art@vandelay.com{ / ELB1111}
  const getOptionLabel = useCallback(
    ({ fullName, email, activeElbCard, totalSalesInHuf, totalSalesInEur }) => {
      const fullNameTemplate = fullName ? `${fullName} / ` : '';
      const activeElbCardTemplate = activeElbCard ? ` / ${activeElbCard.cardNumber}` : '';
      let fullOption = `${fullNameTemplate}${email}${activeElbCardTemplate}`;
      if (isCustomersTable) {
        fullOption += ` / Total sale: ${totalSalesInHuf} HUF, ${totalSalesInEur} EUR`;
      }
      return fullOption;
    },
    [isCustomersTable],
  );
  const groupBy = useCallback(({ fullName }) => (fullName ? fullName[0].toUpperCase() : '?'), []);

  const onChangeHandler = (customer) => {
    const selectedCustomer = options.find(option => customer.includes(`${option.fullName} / ${option.email}`));
    if (selectedCustomer) {
      debouncedSetFieldText.cancel();
      history.push(routes.customer.createLink(selectedCustomer.id, selectedCustomer.fullName).props.to)
    }
  }

  return (
    <>
      <FAutocomplete
        name={name}
        options={options}
        groupBy={groupBy}
        loading={loading}
        isLoading={isLoading}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        InputProps={{
          label: 'Customer',
          InputProps: {
            startAdornment: isCustomersTable === false && (
              <InputAdornment position="start">
                <IconButton onClick={handleOpen} className={classes.addButton}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          },
          ...InputProps,
        }}
        {...rest}
      />

      {isCustomersTable === false && (
        <>
          <CustomerAutocompleteInfo customer={customer} toggleElbCard={toggleElbCard} />

          {customer && <AutocompleteActiveOnlineCardInfo customer={customer} />}

          <DialogForm
            {...dialogProps}
            open={open}
            onClose={handleClose}
            formikProps={extendedFormikProps}
            snackbarProps={snackbarProps}
          />
        </>
      )}
    </>
  );
}

FormikCustomerAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
  toggleElbCard: PropTypes.bool,
};

export default FormikCustomerAutocomplete;
