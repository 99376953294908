import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { getHumanReadableProductType } from '../Products/products.utils';
import { CheckBox, CheckBoxOutlineBlank, Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';

function ProductCard({ productData, isSuccess, isRevision = false, displayEditBtn = true }) {
  const history = useHistory();
  return (
    <>
      {isSuccess &&
        (productData?.length > 0 ? (
          <Box>
            {productData.map(product => {
              const destructedProduct = isRevision ? product.snapshot : product;
              const {
                _id: id,
                type,
                name,
                description,
                priceInHuf,
                imageUrl,
                isWebshopPurchasable,
                includedInOrderEmail,
                isMembershipGranted,
                membershipExpiresInDays,
                ticketLimit,
                needsPostProcessing,
              } = destructedProduct;
              const { createdAt } = product;
              const productDetailsColumnWidth =
                imageUrl && displayEditBtn
                  ? 4
                  : (imageUrl && !displayEditBtn) || (!imageUrl && displayEditBtn)
                  ? 5
                  : 6;
              return (
                <Card style={{ margin: '8px 0px' }} key={id}>
                  <CardContent>
                    <Grid container direction="row" style={{ marginTop: '8px' }}>
                      {imageUrl && (
                        <Grid item xs={2} style={{ padding: '4px' }}>
                          {<img src={imageUrl} alt={name} width="100%" />}
                        </Grid>
                      )}
                      <Grid item xs={productDetailsColumnWidth}>
                        <Grid container direction="row" style={{ padding: '0px 16px' }}>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="subtitle1">
                              Name: <strong>{name}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="subtitle1">
                              Description: <strong>{description}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="subtitle1">
                              Price (HUF): <strong>{priceInHuf} Ft</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="subtitle1">
                              Type: <strong>{getHumanReadableProductType(type)}</strong>
                            </Typography>
                          </Grid>
                          {type === 'ticket' && (
                            <Grid item xs={12} style={{ padding: '4px' }}>
                              <Typography variant="subtitle1">
                                Ticket Limit: <strong>{ticketLimit}</strong>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={productDetailsColumnWidth}>
                        <Grid container direction="row" style={{ padding: '0px 16px' }}>
                          <Grid item xs={12} style={{ padding: '4px', margin: '4px' }}>
                            <Box display={'flex'} alignItems={'center'} justifyItems={'flex-start'}>
                              <Typography variant="body1" style={{ marginRight: '4px' }}>
                                Purchasable in webshop:{' '}
                              </Typography>
                              {isWebshopPurchasable ? (
                                <CheckBox htmlColor="green" />
                              ) : (
                                <CheckBoxOutlineBlank htmlColor="red" />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px', margin: '4px' }}>
                            <Box display={'flex'} alignItems={'center'} justifyItems={'flex-start'}>
                              <Typography variant="body1" style={{ marginRight: '4px' }}>
                                Included in email:{' '}
                              </Typography>
                              {includedInOrderEmail ? (
                                <CheckBox htmlColor="green" />
                              ) : (
                                <CheckBoxOutlineBlank htmlColor="red" />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px', margin: '4px' }}>
                            <Box display={'flex'} alignItems={'center'} justifyItems={'flex-start'}>
                              <Typography variant="body1" style={{ marginRight: '4px' }}>
                                Adds membership:
                              </Typography>
                              {isMembershipGranted ? (
                                <CheckBox htmlColor="green" />
                              ) : (
                                <CheckBoxOutlineBlank htmlColor="red" />
                              )}
                              <Typography variant="body1">
                                {membershipExpiresInDays && `-- ${membershipExpiresInDays} days`}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px', margin: '4px' }}>
                            <Box display={'flex'} alignItems={'center'} justifyItems={'flex-start'}>
                              <Typography variant="body1" style={{ marginRight: '4px' }}>
                                Needs post processing:{' '}
                              </Typography>
                              {needsPostProcessing ? (
                                <CheckBox htmlColor="green" />
                              ) : (
                                <CheckBoxOutlineBlank htmlColor="red" />
                              )}
                            </Box>
                          </Grid>
                          {isRevision && (
                            <Grid item xs={12} style={{ padding: '4px' }}>
                              <Typography variant="body1">
                                Revision was created at:{' '}
                                {format(new Date(createdAt), 'yyyy-MM-dd HH:mm')}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {displayEditBtn && (
                        <Grid item xs={2} style={{ padding: '4px' }}>
                          <Edit
                            onClick={() => {
                              history.push(`products/id=${id}`);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        ) : (
          <Alert severity="warning">
            <AlertTitle>Products</AlertTitle>
            <Typography>There is no product to display.</Typography>
          </Alert>
        ))}
    </>
  );
}

export default ProductCard;
