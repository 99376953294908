import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography } from '@material-ui/core';

const useGetActiveElbCard = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['active-elb-card-number'],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_SERVER_URL}dashboard/number-of-active-cards`);
      const data = await response.data;
      return data;
    },
  });
};

const ActiveElbCard = () => {
  const { data, isSuccess } = useGetActiveElbCard();
  return (
    <Box
      style={{
        border: '1px solid black',
        width: '230px',
        height: '100px',
        margin: '8px',
        padding: '8px',
        backgroundColor: 'white',
        borderRadius: '5px',
      }}
    >
      <Typography style={{ fontWeight: '400', fontSize: '18px', marginBottom: '24px' }}>
        Active ELB Cards Count
      </Typography>
      <Typography style={{ fontWeight: 'bold', fontSize: '60px' }}>
        {isSuccess && data.numberOfActiveCards}
      </Typography>
    </Box>
  );
};

export default ActiveElbCard;
