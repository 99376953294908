import { useMutation, useQueryClient, } from '@tanstack/react-query';
import axios from 'axios';

export default function useCreateEventTicketTypes() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['create-event-ticket-types'],
        mutationFn: async (payload) => {
            console.log({ payload });
            const response = await axios.post(`${REACT_APP_SERVER_URL}event-ticket-types`, payload);
            console.log(response?.data);
            return response?.data;
        },
        refetchOnWindowFocus: false,
        onSuccess: () => Promise(
            queryClient.invalidateQueries(['event-ticket-types']),
        )
    });
}