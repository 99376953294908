import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useCoupons() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['coupons'],
        queryFn: async () => {
            const response = await axios.get(`${REACT_APP_SERVER_URL}coupons/`);
            return response?.data;
        },
        refetchOnWindowFocus: false,
    });
}
