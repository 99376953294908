import React from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useGetV2Tickets } from './useGetV2Tickets.hook';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../payments/components/Payment/Payment';
import { format } from 'date-fns';
import Privileges from '../../utils/access-control/privileges.json';
import { useCheckPrivilege } from '../lib/hooks';

function V2TicketsPage() {
  const { data, isSuccess, isLoading, isError } = useGetV2Tickets();
  const downloadAccess = useCheckPrivilege(Privileges.DOWNLOAD_TICKET);
  const colspanNumber = downloadAccess ? 10 : 9;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Ticket Type</TableCell>
            {/*<TableCell>Purchased with ELB Card</TableCell>*/}
            <TableCell>Price</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Customer Email</TableCell>
            <TableCell>Sold at</TableCell>
            <TableCell>Status</TableCell>
            {downloadAccess && <TableCell>Download</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && (
            <TableRow>
              <TableCell colSpan={colspanNumber}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Typography>Something went wrong!</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow
              style={{
                position: 'relative',
                height: '110px',
              }}
            >
              <TableCell colSpan={colspanNumber}>
                <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            (data.tickets.length > 0 ? (
              data.tickets.map(ticket => {
                const { _id, event, status, ticketNumber, createdDate, customer, product: { name, priceInHuf } } = ticket;
                const { name: eventName, ticketTypes } = event || {};
                const { name: eventTicketType } = ticketTypes?.[0] || {};
                const { fullName, email } = customer || {};
                const humanReadableStatus =
                  status === 'ReservedForOnlinePayment'
                    ? 'Reserved'
                    : status === 'FinalizedByOnlinePayment'
                      ? 'Finalized'
                      : status;

                return (
                  <TableRow key={_id}>
                    <TableCell>{ticketNumber}</TableCell>
                    <TableCell>{eventName}</TableCell>
                    {/*<TableCell>{eventTicketType}</TableCell>*/}
                    <TableCell>{name}</TableCell>
                    <TableCell>{priceInHuf} HUF</TableCell>
                    <TableCell>{fullName}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{format(new Date(createdDate), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{humanReadableStatus}</TableCell>
                    {downloadAccess && <TableCell>{/* <DownloadTicket id={_id} /> */}</TableCell>}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={colspanNumber}>
                  <Alert severity="warning">
                    <AlertTitle>Tickets</AlertTitle>
                    <Typography style={{ fontSize: '16px' }}>
                      There is no ticket to display!
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default V2TicketsPage;
