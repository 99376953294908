import React from 'react';
import * as PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Typography, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useOptions } from '../../hooks';
import * as TableTypes from '../../propTypes';
import { formatEuro, formatForint } from '../../../../../../utils/formatters';
import { getHumanReadableProductType } from '../../../../../products/components/Products/products.utils';
import routes from '../../../../../../utils/routes';
/**
 * @see https://github.com/gregnb/mui-datatables
 */
function MuiDataTable({ state, options: optionsProp }) {
  const { title, columns, data, hasMore, loading } = state;
  const options = useOptions(optionsProp, hasMore, data.length, loading);

  const findOrderById = (orders, id) => {
    return orders.filter(order => order.id === id);
  };

  const mapTicketDataToDisplay = ticket => {
    return {
      id: ticket?.ticketNumber2ndGen || ticket.ticketNumber,
      name: `Ticket for event: ${ticket.event.name}`,
      type: ticket.ticketType.name,
      amountInHuf: ticket.priceInHuf || 0,
      amountInEur: ticket.priceInEur || 0,
      customerName: ticket.customer.fullName,
      customerEmail: ticket.customer.email,
      item: 'Ticket',
      mongoId: ticket.id,
    };
  };

  const mapV2TicketDataToDisplay = v2Ticket => {
    return {
      id: v2Ticket?.ticketNumber,
      name: `Ticket for event: ${v2Ticket?.event?.name}`,
      type: v2Ticket.product?.name || 'Cannot find type',
      amountInHuf: v2Ticket?.product?.priceInHuf || v2Ticket?.product?.price || 0,
      amountInEur: v2Ticket?.product?.priceInEur || 0,
      customerName: v2Ticket?.customer?.fullName,
      customerEmail: v2Ticket?.customer?.email,
      item: 'V2-Ticket',
      mongoId: v2Ticket.id,
    };
  };

  const mapCardDataToDisplay = card => {
    return {
      id: card.cardNumber,
      name: 'Erasmus Card',
      type: card.type.name,
      amountInHuf: card.type?.price || card.type?.priceInHuf || 0,
      amountInEur: card.type?.priceInEur || 0,
      customerName: card.customer.fullName,
      customerEmail: card.customer.email,
      item: 'ELB Card',
      mongoId: card.id,
    };
  };

  const mapProductDataToDisplay = product => {
    return {
      name: product.name,
      type: getHumanReadableProductType(product.type),
      amountInHuf: product?.priceInHuf || product?.price || 0,
      amountInEur: product?.priceInEur || 0,
      customerName: product?.customer?.fullName || '',
      customerFirstName: product?.customer?.firstName || '',
      customerLastName: product?.customer?.lastName || '',
      customerEmail: product?.customer?.email || '',
    };
  };

  const renderOrderDetailsForExpandedRow = rowData => {
    const expandedOrder = findOrderById(state.data, rowData[0]);
    const tickets = expandedOrder[0].tickets.map(mapTicketDataToDisplay);
    const cards = expandedOrder[0].elbCards.map(mapCardDataToDisplay);
    const v2Tickets = expandedOrder[0]?.v2Tickets?.map(mapV2TicketDataToDisplay);
    const products = expandedOrder[0].products.map(mapProductDataToDisplay);
    const items = [...tickets, ...cards, ...v2Tickets];
    const colSpan = rowData.length + 1;
    const currency = expandedOrder[0].currency;
    const snapshotExchangeRate = expandedOrder[0]?.exchangeRate;

    const displayItemsTable = items.length > 0 && products.length === 0;
    const displayProductsTable = products.length > 0 || (products.length > 0 && items.length > 0);

    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          {displayItemsTable && (
            <>
              <TableContainer component={Paper} style={{ margin: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item name</TableCell>
                      <TableCell>Item type</TableCell>
                      <TableCell>Ticket or ELB card ID</TableCell>
                      <TableCell>Customer name</TableCell>
                      <TableCell>Customer email</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map(item => {
                      const amountFromCurrency =
                        currency === 'HUF' || !currency
                          ? item.amountInHuf !== 0
                            ? formatForint(Math.round(item.amountInHuf / 10) * 10)
                            : formatForint(
                                Math.round(
                                  parseInt(
                                    (item.amountInEur * snapshotExchangeRate).toFixed(0) / 10,
                                  ) * 10,
                                ),
                              )
                          : item.amountInEur !== 0
                          ? formatEuro(item.amountInEur)
                          : formatEuro(
                              parseFloat((item.amountInHuf / snapshotExchangeRate).toFixed(2)),
                            );
                      const linkToItem =
                        item.item === 'ELB Card'
                          ? routes.elbCard.createLink(item.mongoId, item.id)
                          : item.item === 'Ticket'
                          ? routes.ticket.createLink(item.mongoId, item.id)
                          : item.id;
                      return (
                        <TableRow>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>{linkToItem}</TableCell>
                          <TableCell>{item.customerName}</TableCell>
                          <TableCell>{item.customerEmail}</TableCell>
                          <TableCell>{amountFromCurrency}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {displayProductsTable && (
            <>
              <TableContainer component={Paper} style={{ margin: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product type</TableCell>
                      <TableCell>Product name</TableCell>
                      <TableCell>Customer name</TableCell>
                      <TableCell>Customer email</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map(product => {
                      const amountFromCurrency =
                        currency === 'HUF' || !currency
                          ? product.amountInHuf !== 0
                            ? formatForint(Math.round(product.amountInHuf / 10) * 10)
                            : formatForint(
                                Math.round(
                                  parseInt(
                                    (product.amountInEur * snapshotExchangeRate).toFixed(0) / 10,
                                  ) * 10,
                                ),
                              )
                          : product.amountInEur !== 0
                          ? formatEuro(product.amountInEur)
                          : formatEuro(
                              parseFloat((product.amountInHuf / snapshotExchangeRate).toFixed(2)),
                            );
                      return (
                        <TableRow>
                          <TableCell>{product.type}</TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>
                            {product.customerName ||
                              `${product.customerFirstName} ${product.customerLastName}`}
                          </TableCell>
                          <TableCell>{product.customerEmail}</TableCell>
                          <TableCell>{amountFromCurrency}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleCustomOrderTableOptions = {
    rowsExpanded: state.title === 'Orders List' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : [],
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return renderOrderDetailsForExpandedRow(rowData, rowMeta);
    },
  };

  const mapOptionsToTable = (options, tableTitle) => {
    switch (tableTitle) {
      case 'Orders List':
        return { ...options, ...handleCustomOrderTableOptions };
      case 'Payment List':
        return { ...options, ...handleCustomOrderTableOptions };
      default:
        return options;
    }
  };

  return (
    <MUIDataTable
      title={
        <Typography variant="h6">
          {title}
          {loading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
          )}
        </Typography>
      }
      columns={columns}
      data={data}
      options={mapOptionsToTable(options, state.title)}
    />
  );
}

MuiDataTable.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  options: TableTypes.muiDataTableOptions.isRequired,
};

export default MuiDataTable;
