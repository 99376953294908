import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import useCreateEventTicketTypes from './useCreateEventTicketType';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useRenameEventTicketTypes from './useRenameEventTicketType';

function CreateOrEditEventTicketType({ eventId, initialName, open, setOpen, id }) {
  const { isLoading, mutate } = useCreateEventTicketTypes();
  const { isLoading: renameLoading, mutate: renameMutate } = useRenameEventTicketTypes(id);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventId: eventId,
      name: initialName ? initialName : '',
    },
    validationSchema: yup.object({
      eventId: yup.string(),
      name: yup.string(),
    }),
    onSubmit: async (values, actions) => {
      console.log('values before mutation: ', { values });
      if (id) {
        renameMutate(values);
      } else {
        mutate(values);
      }
      actions.resetForm();
    },
  });
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Typography>{id ? 'Edit' : 'Create'} Event Ticket Type</Typography>
            <CloseOutlined onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center', width: '100%' }}
                  id="name"
                  name="name"
                  label="Event Ticket Type Name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="eventId"
                  name="eventId"
                  type="hidden"
                  value={formik.values.eventId}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={(formik.isValid && !formik.dirty) || isLoading}
                  >
                    Save
                    {(isLoading || renameLoading) && (
                      <CircularProgress size={18} style={{ marginLeft: '8px' }} />
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrEditEventTicketType;
