import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useExchangeRates(limit) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['exchange-rates'],
        queryFn: async () => {
            const response = await axios.get(`${REACT_APP_SERVER_URL}exchange-rates?limit=${limit}`);
            return response?.data;
        }
    });
}