import React from 'react';

import routes from '../../../../utils/routes';

import { GET_ELB_CARD_TYPES } from '../../graphql';

import { Table, CollectionTable } from '../../../common/components';
import { formatEuro, formatForint } from '../../../../utils/formatters';

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filterType: 'textField',
      renderCell: (value, { id }) => routes.elbCardType.createLink(id, value),
    },
  },
  {
    name: 'priceInHuf',
    label: 'Price (HUF)',
    //type: 'currency',
    options: {
      renderCell: (value, { price, priceInHuf }) =>
        priceInHuf
          ? formatForint(Math.round(priceInHuf / 10) * 10)
          : price
          ? formatForint(price)
          : formatForint(0),
    },
  },
  {
    name: 'priceInEur',
    label: 'Price (EUR)',
    //type: 'currency_eur',
    options: {
      renderCell: priceInEur => (priceInEur ? formatEuro(priceInEur) : '-'),
    },
  },
  {
    name: 'soldAmount',
    label: 'Sold amount',
    type: 'number',
  },
  {
    name: 'forSale',
    label: 'For Sale',
    options: {
      renderCell: forSale => (forSale === true ? 'Yes' : 'No'),
      // filterList: ['Yes'],
      filter: false,
    },
  },
];

function ELBCardTypesTable() {
  return (
    <CollectionTable>
      <Table
        title="ELB Card types List"
        collectionName="elbCardTypes"
        query={GET_ELB_CARD_TYPES}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default ELBCardTypesTable;
