import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as Types from '../../../../utils/propTypes';
import { useCustomerOrders } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FillerSpinner } from '../../../lib/components';
import { Table } from '../../../common/components';
import routes from '../../../../utils/routes';
import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import PaymentChannel from '../../../orders/components/OrdersTable/PaymentChannel';
import OrderStatus from '../../../orders/components/OrdersTable/OrderStatus';

const columns = [
    {
        name: 'orderSeqId',
        label: 'ID',
        options: {
            renderCell: (value, { id, orderSeqId }) => {
                return orderSeqId ? routes.order.createLink(id, `OR${orderSeqId}`) : null;
            },
            filterType: 'textField',
        },
    },
    {
        name: 'createdAt',
        label: 'Created at',
        type: 'dateTime',
        options: {},
    },
    {
        name: 'billingCustomer.fullName',
        label: 'Customer',
        options: {
            renderCell: (value, order) =>
                order.billingCustomer ? routes.customer.createLink(order.billingCustomer.id, value) : '',
            filterType: 'textField',
        },
    },
    {
        name: 'billingCustomer.email',
        label: 'Customer Email',
        options: {
            renderCell: (value, order) =>
                order.billingCustomer
                    ? routes.customer.createLink(
                        order.billingCustomer.id,
                        order.billingCustomer.fullName,
                        value,
                    )
                    : '',
            filterType: 'textField',
        },
    },
    {
        name: 'createdBy.fullName',
        label: 'Employee',
        autocompleteType: AutocompleteTypes.USER,
        options: {
            renderCell: (value, order) => routes.user.createLink(order.createdBy, value),
            filterType: 'autocomplete',
        },
    },
    {
        name: 'status',
        label: 'Status',
        options: {
            filterOptions: Object.values(OrderStatus),
            filterType: 'dropdown',
        },
    },
    {
        name: 'payment',
        label: 'Payment',
        options: {
            renderCell: value => {
                if (value.cashSubtotal === null && value.cardSubtotal === null) {
                    return 'Cash';
                } else if (
                    (value.cashSubtotal && value.cardSubtotal === null) ||
                    (value.cashSubtotal && value.cardSubtotal === 0)
                ) {
                    return 'Cash';
                } else if (
                    (value.cardSubtotal && value.cashSubtotal === null) ||
                    (value.cardSubtotal && value.cashSubtotal === 0)
                ) {
                    return 'Card';
                } else if (value.cashSubtotal && value.cardSubtotal) {
                    return 'Mixed';
                } else {
                    return 'Unknown';
                }
            },
        },
    },
    {
        name: 'amount',
        label: 'Amount',
        type: 'currency',
    },
    {
        name: 'items',
        label: 'Number of Items',
        type: 'number',
    },
    {
        name: 'paymentChannel',
        label: 'Payment Channel',
        options: {
            filterOptions: Object.values(PaymentChannel),
            filterType: 'dropdown',
        },
    },
];

function CustomerOrders({ customer }) {
    const { id } = customer;
    const { data, isError, isSuccess, isLoading } = useCustomerOrders(id);

    const [sortModel, setSortModel] = useState({
        name: 'orderSeqId',
        sort: 'desc',
    });

    return (
        <Grid>
            {isError && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong!</Typography>
                </Alert>
            )}
            {isLoading && <FillerSpinner />}
            {isSuccess && (
                <>
                    <Typography variant="h3" style={{ marginBottom: "8px" }}>Orders</Typography>
                    <Table
                        title="Orders List"
                        collectionName="orders"
                        //query={orders ? undefined : GET_ORDERS}
                        data={data}
                        columns={columns}
                        inline={false}
                        sortModel={sortModel}
                        onSortModelChange={model => setSortModel(model)}
                    /></>
            )}
        </Grid>
    );
}

CustomerOrders.propTypes = {
    customer: Types.customer.isRequired,
};

export default CustomerOrders;
