import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useGetEventExchangeRate, useUpdateExchangeRate } from '../../hooks';
import { CloseOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';

export default function UpdateExchangeRateDialog({ eventId }) {
  const [open, setOpen] = useState(false);
  const { isLoading, mutate, isSuccess } = useUpdateExchangeRate(eventId);
  const { data } = useGetEventExchangeRate(eventId);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      newExchangeRate: data?.exchangeRate ? data?.exchangeRate : 0,
    },
    validationSchema: yup.object({
      newExchangeRate: yup.number().required(),
    }),
    onSubmit: async (values, actions) => {
      console.log('values before mutation: ', values);
      mutate(values);
      actions.resetForm();
    },
  });

  useEffect(() => {
    formik.setFieldValue('newExchangeRate', data?.exchangeRate);
  }, [data]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {data?.exchangeRate ? `Update` : 'Set'} Exchange Rate
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          <Grid container justify="space-between">
            <Typography>Create Product</Typography>
            <CloseOutlined onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center', width: '100%' }}
                  id="newExchangeRate"
                  name="newExchangeRate"
                  label="Exchange Rate"
                  type="text"
                  value={formik.values.newExchangeRate}
                  onChange={formik.handleChange}
                  error={formik.touched.newExchangeRate && Boolean(formik.errors.newExchangeRate)}
                  helperText={formik.touched.newExchangeRate && formik.errors.newExchangeRate}
                />
              </Grid>
              <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={(formik.isValid && !formik.dirty) || isLoading}
                  >
                    Save
                    {isLoading && <CircularProgress size={18} style={{ marginLeft: '8px' }} />}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
