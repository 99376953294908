import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import UpdateTerminal from '../UpdateTerminal/UpdateTerminal';
import { ReportTerminalBalance } from '../../../audit/components';

function TerminalToolbar({ terminal }) {
  const accessUpdate = useCheckPrivilege(Privileges.UPDATE_ACCOUNT);
  const accessAuditLog = useCheckPrivilege(Privileges.CREATE_TERMINAL_AUDIT_LOG);
  if (!accessUpdate) return null;
  if (!accessAuditLog) return null;

  return (
    <Toolbar>
      {terminal.account ? <ReportTerminalBalance id={terminal.id} /> : null}
      <UpdateTerminal terminal={terminal} />
    </Toolbar>
  );
}

TerminalToolbar.propTypes = {
  terminal: Types.elbCardType.isRequired,
};

export default TerminalToolbar;
