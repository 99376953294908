import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_EVENT } from '../../graphql';

import { Document, WithDocumentQuery } from '../../../common/components';
import { TicketsTable } from '../../../tickets/components';
import { EventToolbar, EventInfoCard, TicketTypesTable } from '..';
import globalApplicationConfiguration from '../../../../config/config';
import EventProductSales from '../EventProductSales/EventProductSales';
import MergedTicketsTable from '../../../tickets-v2/MergedTicketsTable';
import { useGetEventTicketLength } from '../../hooks';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Event({
  match: {
    params: { id },
  },
}) {
  const featureFlagProductsDisplayTicketProductsTableOnEventPage =
    globalApplicationConfiguration.featureFlags.products.displayTicketProductsTableOnEventPage;
  const { data } = useGetEventTicketLength(id);
  return (
    <WithDocumentQuery id={id} query={GET_EVENT}>
      {(event, tickets) => (
        <Document
          toolbar={<EventToolbar event={event} />}
          infoCard={<EventInfoCard event={event} />}
          inlineTable={
            data.v1TicketLength !== 0 && data.v2TicketLength !== 0 ? (
              <>
                <TicketTypesTable event={event} inline />
                <EventProductSales eventId={event.id} />
              </>
            ) : data.v1TicketLength === 0 && data.v2TicketLength === 0 ? (
              <Alert severity="info">
                <AlertTitle>INFO</AlertTitle>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  No Ticket Was Sold Before
                </Typography>
              </Alert>
            ) : data.v1TicketLength === 0 ? (
              <EventProductSales eventId={event.id} />
            ) : (
              <TicketTypesTable event={event} inline />
            )
          }
          table={
            featureFlagProductsDisplayTicketProductsTableOnEventPage && (
              <>
                {/* <EventProductSales eventId={event.id} /> */}
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Tickets Table
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MergedTicketsTable eventId={event.id} />
                  </AccordionDetails>
                </Accordion>
              </>
            )
          }
          secondTable={<TicketsTable tickets={tickets} event={event} />}
        />
      )}
    </WithDocumentQuery>
  );
}

Event.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Event;
