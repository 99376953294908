import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';


export default function useUpdateExchangeRate(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['update-exchange-rate', eventId],
        mutationFn: async (payload) => {
            const response = await axios.patch(
                `${REACT_APP_SERVER_URL}update-exchange-rate?eventId=${eventId}`,
                payload
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
        onSuccess: () =>
            queryClient.invalidateQueries(['event-exchange-rate']),
    });
};
