import React from 'react';

import * as Types from '../../../../utils/propTypes';

import MutateTicketTypeWithCurrency from '../MutateTicketType/MutateTicketTypeWithCurrency';

function EditTicketType({ ticketType }) {
  return <MutateTicketTypeWithCurrency ticketType={ticketType} />;
}

EditTicketType.propTypes = {
  ticketType: Types.ticketType.isRequired,
};

export default EditTicketType;
