import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_ELB_CARD } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import { ELBCardToolbar, ELBCardInfoCard } from '..';
import CreateOrEditNote from '../../../notes/CreateOrEditNote.component';
import { Box } from '@material-ui/core';
import NotesTable from '../../../notes/NotesTable.component';

function ELBCard({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_ELB_CARD}>
      {elbCard => (
        <Document
          toolbar={
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <ELBCardToolbar elbCard={elbCard} />
              <CreateOrEditNote elbCardId={id} />
            </Box>
          }
          infoCard={<ELBCardInfoCard elbCard={elbCard} />}
          table={<NotesTable id={id} relatedField={'elb-card'} />}
        />
      )}
    </WithDocumentQuery>
  );
}

ELBCard.propTypes = {
  match: Types.idMatch.isRequired,
};

export default ELBCard;
