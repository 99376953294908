import React, { useEffect, useRef, useState } from 'react';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';

import { format } from 'date-fns';
import { useGetPayments } from '../../hooks/useGetPayments';
import { useDebounce } from '../../../common/hooks';
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Replay as RefundIcon,
  Remove,
} from '@material-ui/icons';
import PaymentRefundDialog from '../PaymentRefundDialog/PaymentRefundDialog';
import PaymentStatusSelect from '../PaymentStatusSelect';

export const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1,
  },
})(Backdrop);

function Payment() {
  const [searchText, setSearchText] = useState('');
  const [lastSearchTextValue, setLastSearchTextValue] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [status, setStatus] = useState('');
  const searchTextInputRef = useRef(null);

  const dateTimeFormat = 'yyyy-MM-dd HH:mm';
  const uuidV4Last12DigitsIndex = 36 - 12;

  const values = {
    search: debouncedSearchText,
    page,
    status,
  };

  const { isError, isLoading, isSuccess, data: resultData, refetch } = useGetPayments(values);
  console.log({ resultData });

  useEffect(() => {
    if (debouncedSearchText) {
      setLastSearchTextValue(debouncedSearchText);
      if (lastSearchTextValue !== debouncedSearchText) {
        if (searchTextInputRef?.current) {
          searchTextInputRef.current.focus();
          searchTextInputRef.current.select();
        }
      }
    }
  }, [debouncedSearchText, lastSearchTextValue]);

  useEffect(() => {
    const updatedNumberOfPages = Math.ceil(resultData?.total / resultData?.pageSize);
    setPages(updatedNumberOfPages);
  }, [resultData]);

  const [expandedRow, setExpandedRow] = useState(null);
  const handleRowClick = index => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const [expandAll, setExpandAll] = useState(false);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        alignItems={'center'}
        alignContent={'center'}
      >
        <TextField
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: '8px', width: '350px' }}
          label="Payment Search"
          type="text"
          inputRef={searchTextInputRef}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <PaymentStatusSelect status={status} setStatus={setStatus} isStatistics={false} />
        <Button
          style={{ marginLeft: '8px' }}
          variant="outlined"
          color="primary"
          onClick={() => {
            setSearchText('');
            setLastSearchTextValue(null);
            setStatus('');
          }}
        >
          Clear
        </Button>
        <TableContainer
          component={Paper}
          style={
            {
              /*display: 'flex',*/
              /*justifyContent: 'center',*/
            }
          }
        >
          <Table
            style={{
              minWidth: '650px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <IconButton onClick={() => setExpandAll(!expandAll)}>
                    {expandAll ? <Remove /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
                <TableCell>Public payment ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell key={Math.random()}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      <Typography>Something went wrong!</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
              {isLoading && (
                <TableRow
                  style={{
                    position: 'relative',
                    height: '110px',
                  }}
                >
                  <TableCell colSpan={8}>
                    <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                      <CircularProgress color="primary" />
                    </LimitedBackdrop>
                  </TableCell>
                </TableRow>
              )}
              {isSuccess &&
                (resultData.data?.length > 0 ? (
                  resultData.data.map(payment => {
                    const {
                      _id,
                      amount,
                      currency,
                      customerEmail,
                      customerFamilyName,
                      customerFirstName,
                      publicPaymentId,
                      products,
                      status,
                      cancelledAt,
                      paidAt,
                      refundedAt,
                      timeoutedAt,
                    } = payment;

                    const humanReadableStatus =
                      status === 'CANCELLED_PAYMENT'
                        ? 'Cancelled'
                        : status === 'PAYMENT_SUCCEEDED'
                        ? 'Paid'
                        : status === 'TRANSACTION_STARTED'
                        ? 'In progress'
                        : status === 'REFUNDED'
                        ? 'Refunded'
                        : status === 'TIMEOUTED'
                        ? 'Timeouted'
                        : 'Unknown state';

                    // const shortenedProductName = productName?.substring(
                    //   0,
                    //   productName.indexOf('('),
                    // );

                    // const productDetailsInTooltip = `${productQuantity} x ${productName}`;

                    return (
                      <>
                        <TableRow key={_id}>
                          <TableCell>
                            {products && (
                              <IconButton onClick={() => handleRowClick(_id)}>
                                {expandedRow === _id || expandAll ? (
                                  <KeyboardArrowRight />
                                ) : (
                                  <KeyboardArrowDown />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell>{publicPaymentId?.slice(uuidV4Last12DigitsIndex)}</TableCell>
                          <TableCell>{`${amount} ${currency}`}</TableCell>
                          <TableCell>{`${customerFirstName} ${customerFamilyName}`}</TableCell>
                          <TableCell>{customerEmail}</TableCell>
                          <TableCell>{humanReadableStatus}</TableCell>
                          <TableCell>
                            {status === 'CANCELLED_PAYMENT'
                              ? format(new Date(cancelledAt), dateTimeFormat)
                              : status === 'PAYMENT_SUCCEEDED'
                              ? format(new Date(paidAt), dateTimeFormat)
                              : status === 'REFUNDED'
                              ? format(new Date(refundedAt), dateTimeFormat)
                              : status === 'TIMEOUTED'
                              ? format(new Date(timeoutedAt), dateTimeFormat)
                              : '-'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {status === 'PAYMENT_SUCCEEDED' ? (
                              <RefundIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpen(true);
                                  setOrderId(publicPaymentId);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                        {products && (
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                              <Collapse in={expandedRow === _id || expandAll}>
                                <Table size="small">
                                  <TableHead>
                                    <TableCell>Product Name</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price</TableCell>
                                  </TableHead>
                                  <TableBody>
                                    {products.map(product => {
                                      const { name, quantity, price } = product;
                                      const mapKey = `_id+${name}-${price}+${Math.random()}`;
                                      return (
                                        <TableRow key={mapKey}>
                                          <TableCell>{name}</TableCell>
                                          <TableCell>{quantity}</TableCell>
                                          <TableCell>{`${price} ${currency}`}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Alert severity="warning">
                        <AlertTitle>Payments</AlertTitle>
                        <Typography style={{ fontSize: '16px' }}>
                          There is no payment to display!
                        </Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                ))}{' '}
            </TableBody>
          </Table>
        </TableContainer>
        {isSuccess && pages > 0 && (
          <Pagination
            style={{ margin: '8px' }}
            color="primary"
            count={pages}
            page={parseInt(resultData?.page)}
            onChange={(_, newPage) => setPage(newPage)}
          />
        )}
        <PaymentRefundDialog
          publicPaymentId={orderId}
          open={open}
          setOpen={setOpen}
          refetch={refetch}
        />
      </Box>
    </div>
  );
}

export default Payment;
