import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetEventTicketTypes(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['event-ticket-types'],
        queryFn: async () => {
            const endpoint = eventId ? `?eventId=${eventId}` : ''
            const response = await axios.get(`${REACT_APP_SERVER_URL}event-ticket-types${endpoint}`);
            console.log(response?.data);
            return response?.data;
        },
        refetchOnWindowFocus: false,
    });
}