import React from 'react';
import CreateOrEditProduct from '../CreateOrEditProduct';
import { useGetProductById, useGetProductRevisionsById, useUpdateProduct } from '../../hooks';
import ProductCard from '../ProductCard';
import { Box, Typography } from '@material-ui/core';

function Product({
  match: {
    params: { id },
  },
}) {
  const { data } = useGetProductById(id);
  const { data: revision, isSuccess } = useGetProductRevisionsById(id);
  const { isError, error, isLoading, mutate } = useUpdateProduct(id);
  return (
    <>
      <CreateOrEditProduct
        error={error}
        isError={isError}
        isLoading={isLoading}
        mutate={mutate}
        isEditing={true}
        initialProductData={data}
      />
      {revision?.length > 0 && (
        <Box style={{ marginTop: '16px' }}>
          <Typography variant="h3" style={{ marginTop: '16px' }}>
            Revisions
          </Typography>
          <ProductCard
            productData={revision}
            isSuccess={isSuccess}
            isRevision={true}
            displayEditBtn={false}
          />
        </Box>
      )}
    </>
  );
}

export default Product;
