import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useCreateCoupons() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['create-coupons'],
        mutationFn: async (payload) => {
            const ELB_BACKOFFICE_ADMIN_APP_TOKEN = localStorage.getItem('token')
            const response = await axios.post(`${REACT_APP_SERVER_URL}coupons/`, payload, { headers: { Authorization: `${ELB_BACKOFFICE_ADMIN_APP_TOKEN}` } });
            return response?.data;
        },
        onSuccess: () =>
            queryClient.invalidateQueries(['coupons']),
    });
}
