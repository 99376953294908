import React, { useEffect } from 'react';

import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import useReadElbCard from '../../hooks/useReadElbCard';

function ReadElbCard({
  cardNumber,
  setCardNumber,
  sendReceivedElbCardData,
  sendStateOfQuery,
  setShowButtons,
  setApprovedIcon,
}) {
  const { isSuccess, data, isError, isLoading } = useReadElbCard(cardNumber);
  const handleSubmit = e => {
    e.preventDefault();
  };
  console.log('cardNumber: ', cardNumber);

  useEffect(() => {
    if (isSuccess) {
      sendReceivedElbCardData(data);
      sendStateOfQuery({ isSuccess, isError, isLoading });
      setShowButtons(true);
      setApprovedIcon(false);
    }
  }, [isSuccess, data, sendReceivedElbCardData, sendStateOfQuery, setShowButtons, setApprovedIcon]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true,
        }}
        name="cardNumber"
        label="ELB Card Number"
        type="text"
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value)}
      />

      <Button variant="contained" color="primary" type="submit">
        Read ELB Card
      </Button>
    </form>
  );
}

export default ReadElbCard;
