import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetCardReadingNumbers(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['card-reading-numers', eventId],
        queryFn: async () => {
            const response = await axios.get(`${REACT_APP_SERVER_URL}checking/checking-statistics/${eventId}`);
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
}