import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../lib/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import useCreateNote from './useCreateNote.hook';
import useUpdateNote from './useUpdateNote.hook';
import { Edit } from '@material-ui/icons';
import { useGetCurrentUser } from '../auth/hooks';

function CreateOrEditNote({ noteId, message, orderId, customerId, elbCardId, isEditing }) {
  const { mutate: createNote, isSuccess, isError } = useCreateNote();
  const { mutate: updateNote, error } = useUpdateNote(noteId);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { data } = useGetCurrentUser();
  console.log({ data });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      enqueueSnackbar('Succesfully created note!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Couldn't create note!", 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  const formik = useFormik({
    initialValues: {
      message: message || '',
      orderId: orderId || null,
      elbCardId: elbCardId || null,
      customerId: customerId || null,
    },
    validationSchema: yup.object({
      message: yup.string().required('Message of the note is required'),
      orderId: yup
        .string()
        .optional()
        .nullable(),
      elbCardId: yup
        .string()
        .optional()
        .nullable(),
      customerId: yup
        .string()
        .optional()
        .nullable(),
    }),
    onSubmit: async (values, actions) => {
      if (isEditing) {
        updateNote(values);
        console.log({ error });
        handleClose();
      } else {
        createNote(values);
        isSuccess && handleClose();
      }
    },
  });

  return (
    <Box style={{ display: 'flex', justifyContent: isEditing ? 'flex-start' : 'flex-end' }}>
      {isEditing ? (
        <Edit
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpen();
          }}
        />
      ) : (
        <Button
          style={{ marginLeft: '4px' }}
          variant="contained"
          color="primary"
          onClick={handleOpen}
        >
          Create Note
        </Button>
      )}
      <Dialog open={open}>
        <DialogTitle>Create Note</DialogTitle>
        <DialogContent>
          <form>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                required
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                id="message"
                name="message"
                label="Note Message"
                type="text"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!formik.isValid || !formik.dirty}
            onClick={formik.handleSubmit}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateOrEditNote;
