import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useCreateNote() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['create-note'],
        mutationFn: async (payload) => {
            const ELB_BACKOFFICE_ADMIN_APP_TOKEN = localStorage.getItem('token')
            payload.token = ELB_BACKOFFICE_ADMIN_APP_TOKEN
            const response = await axios.post(`${REACT_APP_SERVER_URL}notes`, payload);
            return response?.data;
        },
        onSuccess: () =>
            queryClient.invalidateQueries(['notes']),
    });
}
