import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { Table, CollectionTable } from '../../../common/components';
import { formatEuro, formatForint } from '../../../../utils/formatters';
import { Typography } from '@material-ui/core';

function TicketTypesTable({ event, inline = false }) {
  const renderNormalPriceInHufCell = ticketTypes => {
    return ticketTypes?.normalPrice
      ? formatForint(ticketTypes?.normalPrice)
      : ticketTypes?.normalPriceInHuf
      ? formatForint(ticketTypes?.normalPriceInHuf)
      : '-';
  };
  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filterType: 'textField',
        renderCell: (value, { id }) =>
          routes.ticketType.createLink(id, value, event.id, event.name),
      },
    },
    {
      name: 'normalPrice',
      label: 'Price',
      //type: 'currency',
      options: {
        renderCell: (value, { normalPrice, normalPriceInHuf, normalPriceInEur }) => {
          const formattedPrizeInHuf = normalPrice || Math.round((normalPriceInHuf / 10) * 10);
          if ((normalPriceInHuf || normalPrice) && normalPriceInEur) {
            return (
              <>
                <Typography>{formatForint(formattedPrizeInHuf)}</Typography>
                <Typography>{formatEuro(normalPriceInEur)}</Typography>
              </>
            );
          } else if ((normalPriceInHuf || normalPrice) && !normalPriceInEur) {
            return <Typography>{formatForint(formattedPrizeInHuf)}</Typography>;
          } else if ((!normalPriceInHuf || !normalPrice) && normalPriceInEur) {
            return <Typography>{formatEuro(normalPriceInEur)}</Typography>;
          } else {
            return <Typography align="center">-</Typography>;
          }
        },
      },
    },
    {
      name: 'elbCardPrice',
      label: 'ELB Card Price',
      //type: 'currency',
      options: {
        renderCell: (value, { elbCardPrice, elbCardPriceInHuf, elbCardPriceInEur }) => {
          const formattedPrizeInHuf = elbCardPrice || Math.round(elbCardPriceInHuf / 10) * 10;
          if ((elbCardPriceInHuf || elbCardPrice) && elbCardPriceInEur) {
            return (
              <>
                <Typography>{formatForint(formattedPrizeInHuf)}</Typography>
                <Typography>{formatEuro(elbCardPriceInEur)}</Typography>
              </>
            );
          } else if ((elbCardPriceInHuf || elbCardPrice) && !elbCardPriceInEur) {
            return <Typography>{formatForint(formattedPrizeInHuf)}</Typography>;
          } else if ((!elbCardPriceInHuf || !elbCardPrice) && elbCardPriceInEur) {
            return <Typography>{formatEuro(elbCardPriceInEur)}</Typography>;
          } else {
            return <Typography align="center">-</Typography>;
          }
        },
      },
    },
    {
      name: 'ticketStats.active',
      label: 'Active',
      type: 'number',
    },
    {
      name: 'ticketStats.reserved',
      label: 'Reserved',
      type: 'number',
    },
    {
      name: 'limit',
      label: 'Limit',
      type: 'number',
      options: {
        renderCell: value => value || '∞',
      },
    },
    {
      name: 'ticketStats.sold',
      label: 'Sold',
      type: 'number',
    },
    {
      name: 'totalSalesInHuf',
      label: 'Sales made in HUF',
      type: 'currency',
    },
    {
      name: 'totalSalesInEur',
      label: 'Sales made in EUR',
      type: 'currency_eur',
    },
    {
      name: 'totalSalesInHufSum',
      label: 'Total Sales in HUF',
      type: 'currency',
    },
    {
      name: 'totalSalesInEurSum',
      label: 'Total Sales in EUR',
      type: 'currency_eur',
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
      type: 'dateTime',
      options: {
        display: false,
      },
    },
    {
      name: 'forSale',
      label: 'For Sale',
      options: {
        renderCell: forSale => (forSale ? 'Yes' : 'No'),
      },
    },
  ];

  return (
    <CollectionTable>
      <Table title="Ticket Types List" data={event.ticketTypes} columns={columns} inline={inline} />
    </CollectionTable>
  );
}

TicketTypesTable.propTypes = {
  event: Types.event.isRequired,
  inline: PropTypes.bool,
};

export default TicketTypesTable;
