import React from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatDateTime } from '../../../../utils/formatters';
import { Grid } from '@material-ui/core';
import DownloadTicket from '../DownloadTicket';

function TicketCard({ id, ticketNumber, event, ticketType, purchasedWithELBCard, customer, soldBy, soldAt, status, price }) {
  const ticketNumberComponent = routes.ticket.createLink(id, ticketNumber)
  const eventNameComponent = routes.event.createLink(event.id, event.name)
  const ticketTypeNameComponent = routes.ticketType.createLink(ticketType.id, ticketType.name, event.id, event.name)
  const purchasedWithELBCardComponent = (purchasedWithELBCard ? 'Yes' : 'No')
  let customerFullNameComponent = null;
  let customerEmailComponent = null;
  if (customer) {
    customerFullNameComponent = routes.customer.createLink(customer.id, customer.fullName)
    customerEmailComponent = routes.customer.createLink(customer.id, customer.fullName, customer.email)
  }
  const soldByFullNameComponent = soldBy ? routes.user.createLink(soldBy) : ''
  let downloadComponent = null;
  if (event?.isExternal) downloadComponent = 'External';
  if (status !== 'Active') downloadComponent = 'Inactive';
  downloadComponent = <DownloadTicket id={id} />;
  return (
    <Accordion style={{ padding: 0, marginBottom: "8px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <List dense={true} disablePadding>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Ticket: {ticketNumberComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Event: {eventNameComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Ticket type: {ticketTypeNameComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Purchased with ELB Card: {purchasedWithELBCardComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Price: {price}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Customer: {customerFullNameComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Customer Email: {customerEmailComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Employee: {soldByFullNameComponent}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Sold at: {formatDateTime(soldAt)}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<>Status: {status}</>}
              style={{ margin: "4px", fontSize: "16px" }}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px"
                }
              }}
            />
          </ListItem>
          {downloadComponent && (
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={<>Download: {downloadComponent}</>}
                style={{ margin: "4px", fontSize: "16px" }}
                primaryTypographyProps={{
                  style: {
                    fontSize: "16px"
                  }
                }}
              />
            </ListItem>
          )}
        </List>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary">
              Action button 1
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="secondary">
              Action button 1
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

TicketCard.propTypes = {
  order: Types.order.isRequired,
};

export default TicketCard;

export function TicketCards({ tickets }) {
  const slicedTickets = tickets.slice(0, 10)

  if (!slicedTickets) {
    return null;
  }
  else {
    return slicedTickets.map(ticket => { return <TicketCard {...ticket} /> })
  }
}