import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetV2Tickets = () => {
    return useQuery({
        queryKey: ['tickets-v2'],
        queryFn: async (queryParam) => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const { status } = queryParam;
            const statusQueryParam = status ? `?status=${status}` : ''
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}tickets/v2${statusQueryParam}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
