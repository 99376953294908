import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export default function useGetEventProductSales(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['event-product-sales', eventId],
        queryFn: async () => {
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}webshop/v0/public/events/${eventId}/grouped-tickets`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
