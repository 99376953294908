import React, { useState, useRef, useEffect } from 'react';

import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { MethodWayEnum } from './method-enum';

function NewElbCardReading({ cardNumber, setCardNumber, mutate, debouncedCardNumber }) {
  const currentPath = window.location.pathname;
  const [lastCardNumberValue, setLastCardNumberValue] = useState(null);
  const methodWay = useState(
    currentPath === '/card-reading-last-12-hours' || currentPath.includes('/checking')
      ? MethodWayEnum.LAST_12_HOURS_METHOD
      : MethodWayEnum.SINGLE_READING_PER_USER,
  );
  const userToken = window.localStorage.getItem('token');

  const values = {
    cardNumber: cardNumber,
    userToken: userToken,
    methodWay: methodWay[0],
  };

  const handleSubmit = e => {
    e.preventDefault();
    mutate(values);
    if (cardNumberInputRef?.current) {
      cardNumberInputRef.current.focus();
      cardNumberInputRef.current.select();
    }
  };

  useEffect(() => {
    if (debouncedCardNumber) {
      setLastCardNumberValue(debouncedCardNumber);
      if (lastCardNumberValue !== debouncedCardNumber) {
        console.log('NewElbCardReading useEffect [debouncedCardNumber], call mutate with:', debouncedCardNumber);
        mutate(values);
        if (cardNumberInputRef?.current) {
          cardNumberInputRef.current.focus();
          cardNumberInputRef.current.select();
        }
      }
    }
  }, [debouncedCardNumber, mutate, values, lastCardNumberValue])

  const cardNumberInputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      console.log('Preventing submitting the form with enter');
      event.preventDefault();
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true,
        }}
        name="cardNumber"
        label="ELB Card Number"
        type="text"
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value)}
        autoFocus
        inputRef={cardNumberInputRef}
        onKeyDown={(event) => handleKeyDown(event)}
      />

      <TextField margin="none" required name="userToken" type="hidden" value={userToken} />
      <TextField margin="none" required name="methodWay" type="hidden" value={methodWay} />

      <Button variant="contained" color="primary" type="submit">
        Read ELB Card
      </Button>
    </form>
  );
}

export default NewElbCardReading;
