import React, { useState } from 'react';

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FillerSpinner } from '../../../lib/components';
import { formatDate } from '../../../../utils/formatters';
import { format } from 'date-fns';

import './pulse.css';

import {
  CheckCircle as ApprovedPictureIcon,
  Cancel as DeclinedPictureIcon,
  Help as MissingApprovalIcon,
} from '@material-ui/icons';
import ProfilePicAction from '../ProfilePicAction';
import { useMutation } from '@apollo/react-hooks';
import Privileges from '../../../../utils/access-control/privileges.json';
import {
  EVALUATE_PROFILE_PICTURE,
  GET_CUSTOMER_PROFILE_PICTURES,
} from '../../../online-elb-cards/graphql';
import { getOperationName } from '../../../../apollo/helpers';
import { Block as DeclinePictureIcon } from '@material-ui/icons';
import { useCheckPrivilege } from '../../../lib/hooks';

function ElbCardReadingInfoCard({
  data,
  isError,
  isLoading,
  className,
  showButtons,
  setShowButtons,
  approvedClass,
  approvedIcon,
  setApprovedIcon,
}) {
  const [evaluateProfilePicture] = useMutation(EVALUATE_PROFILE_PICTURE, {
    awaitRefetchQueries: true,
    onCompleted: data => {
      setShowButtons(false);
      console.log('mutation data ', { data });
    },
    refetchQueries: [getOperationName(GET_CUSTOMER_PROFILE_PICTURES)],
  });
  //TODO: remove override privilege from admin
  const access = useCheckPrivilege(Privileges.OVERRIDE_ACCEPTED_PROFILE_PICTURE);
  const [overrideDecline, setOverrideDecline] = useState(false);
  const approved = data?.profilePicture?.approved || false;

  const [openDecline, setOpenDecline] = useState(false);
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const rows = [
    {
      label: 'Checking User',
      value: data?.userFullName,
    },
    {
      label: 'Check Timer',
      value: format(new Date(data.createdDate), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Customer Email',
      value: data.customerEmail,
    },
    {
      label: 'Card Number',
      value: data.cardNumber,
    },
    {
      label: 'Online card number',
      value: data.onlineCardNumber ? data.onlineCardNumber : 'Not filled in yet',
    },
    {
      label: 'Expiration Date',
      value: data.expirationDate ? formatDate(data.expirationDate) : null,
    },
    {
      label: 'Status',
      value: data.status,
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const rows_profilePic = [
    {
      label: 'Customer',
      value: data.customerFullName,
    },
    {
      label: 'Picture',
      value: data?.profilePicture?.url ? (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', justifyContent: 'center', margin: 8 }}>
            <img
              src={data?.profilePicture?.url}
              alt=""
              width="100px"
              onClick={() => handleOpen()}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Grid
            item
            container
            style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
          >
            {approvedClass === 'approved' && className === 'specialClass' && !overrideDecline ? (
              <ApprovedPictureIcon htmlColor="green" />
            ) : (approvedClass === 'declined' && className === 'specialClass') ||
              overrideDecline ? (
              <DeclinedPictureIcon htmlColor="red" />
            ) : !data?.profilePicture?.timeOfApproval ||
              (showButtons && !data?.profilePicture?.timeOfApproval) ? (
              <MissingApprovalIcon htmlColor="orange" />
            ) : data?.profilePicture?.approved ? (
              <ApprovedPictureIcon htmlColor="green" />
            ) : (
              <DeclinedPictureIcon htmlColor="red" />
            )}
          </Grid>
          {((!approvedIcon && approvedClass === 'approved') || !approved) && (
            <>
              <Typography>Click on the picture to approve or decline it</Typography>
            </>
          )}
          {access &&
            (approved || approvedIcon) &&
            !overrideDecline &&
            className === 'specialClass' && (
              <>
                <Grid container direction="row" justify="center" width="100%">
                  <Button
                    variant="contained"
                    startIcon={
                      <DeclinePictureIcon
                        htmlColor="white"
                        style={{
                          cursor: 'pointer',
                          marginRight: '8px',
                        }}
                      />
                    }
                    style={{
                      backgroundColor: '#d32f2f',
                      color: 'white',
                    }}
                    onClick={handleOpenDecline}
                  >
                    Override Decline
                  </Button>
                  <Dialog
                    open={openDecline}
                    onClose={handleCloseDecline}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">DECLINE PICTURE</DialogTitle>
                    <DialogContent>
                      <Typography align="center">
                        Are you sure you want to <strong>DECLINE</strong>{' '}
                        <i>{data?.userFullName}</i>
                        's profile picture:{' '}
                      </Typography>
                      <br />
                      {
                        <img
                          src={data?.profilePicture?.url}
                          alt="decline_profile_pic"
                          width="400px"
                        />
                      }
                    </DialogContent>
                    <DialogActions>
                      <Grid container direction="row" justify="space-between" width="100%">
                        <Button onClick={() => handleCloseDecline()} variant="outlined">
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={
                            <DeclinedPictureIcon
                              htmlColor="white"
                              style={{
                                cursor: 'pointer',
                                marginRight: '8px',
                              }}
                            />
                          }
                          style={{
                            backgroundColor: '#d32f2f',
                            color: 'white',
                          }}
                          onClick={() => {
                            evaluateProfilePicture({
                              variables: {
                                id: data.customerId,
                                approved: false,
                                overrideAccess: true,
                              },
                            });
                            setOverrideDecline(true);
                            handleClose();
                          }}
                        >
                          Override Decline
                        </Button>
                      </Grid>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </>
            )}
          <Dialog open={open} onClose={handleClose} style={{ justifyContent: 'center' }}>
            <DialogContent style={{ fontSize: '20px', textAlign: 'center' }}>
              <p>
                <i>{data.customerFullName}</i>
              </p>
              <p>{data.onlineCardNumber}</p>
              <img src={data?.profilePicture?.url} alt="" width="100%" />
              <Grid
                item
                container
                style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
              >
                {approvedClass === 'approved' && className === 'specialClass' ? (
                  <ApprovedPictureIcon htmlColor="green" />
                ) : approvedClass === 'declined' && className === 'specialClass' ? (
                  <DeclinedPictureIcon htmlColor="red" />
                ) : !data?.profilePicture?.timeOfApproval ||
                  (showButtons && !data?.profilePicture?.timeOfApproval) ? (
                  <MissingApprovalIcon htmlColor="orange" />
                ) : data?.profilePicture?.approved ? (
                  <ApprovedPictureIcon htmlColor="green" />
                ) : (
                  <DeclinedPictureIcon htmlColor="red" />
                )}
              </Grid>
              {className === 'specialClass' && showButtons && (
                <ProfilePicAction
                  data={data}
                  setShowButtons={setShowButtons}
                  setApprovedIcon={setApprovedIcon}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <strong>No picture </strong>
      ),
    },
  ];

  let style = { margin: '8px 0px' };
  if (data.userVoucherWasAlreadyUsed || data.wasReadInLast12Hours) {
    style = { ...style, backgroundColor: 'lightcoral' };
  } else {
    style = { ...style, backgroundColor: 'palegreen' };
  }

  return (
    <>
      {isLoading && <FillerSpinner />}
      {isError && (
        <>
          <strong>Failed checking ELB Card</strong>
        </>
      )}
      {className === 'specialClass' && data.timeBetweenChecks && (
        <Alert severity="info">
          <AlertTitle>Event Card Reading</AlertTitle>
          <p style={{ fontSize: '16px' }}>
            ELB Card with card number: {data.cardNumber} was already checked{' '}
            <strong>{data.timeBetweenChecks} minute(s) ago</strong>!
          </p>
        </Alert>
      )}

      <Card style={style} className={className}>
        <CardContent>
          <Typography variant="h3" component="h1" gutterBottom>
            {`ELB Card: ${data.cardNumber === 'ELB' ? data.onlineCardNumber : data.cardNumber}`}
          </Typography>

          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows_profilePic.map(({ label, value }) => (
                <Grid item container key={label} style={{ justifyContent: 'center' }}>
                  <Grid item>
                    <Typography variant="body1">{value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows.map(({ label, value }) => (
                <Grid item key={label}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">{label}:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <strong>{value}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default ElbCardReadingInfoCard;
