import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';
import { formatForint, formatDateTime, formatEuro } from '../../../../utils/formatters';

import { useIsMe } from '../../../auth/hooks';
import { useCheckPrivilege } from '../../../lib/hooks';

import { InfoCard, InfoCardRow } from '../../../common/components';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

function UserInfoCard({ user: { id, role, email, fullName, account, createdAt, isActive } }) {
  const isMe = useIsMe(id);
  const access = useCheckPrivilege(Privileges.READ_ANY_USER_SALES_DATA);

  const rows = [
    {
      label: 'Role',
      value: role.name,
    },
    {
      label: 'Email',
      value: email,
    },
    {
      label: 'Registration Date',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Status',
      value: isActive ? 'Active' : 'Inactive',
    },
  ];
  if (isMe || access) {
    rows.push(
      ...[
        {
          label: 'Balance (HUF)',
          value:
            account.balance > 0
              ? formatForint(Math.round(account?.balance / 10) * 10)
              : account?.balanceInHuf > 0
              ? formatForint(Math.round(account?.balanceInHuf / 10) * 10)
              : '-',
        },
        {
          label: 'Balance (EUR)',
          value: account.balanceInEur ? formatEuro(account?.balanceInEur) : '-',
        },
        {
          label: 'Sales Today made in HUF',
          value: formatForint(Math.round(account.sales.todayInHuf / 10) * 10),
        },
        {
          label: 'Sales Today made in EUR',
          value: formatEuro(account.sales.todayInEur),
        },
        {
          label: 'Total Sales Today in HUF',
          value: formatForint(Math.round(account.sales.todayInHufSum / 10) * 10),
        },
        {
          label: 'Total Sales Today in EUR',
          value: formatEuro(account.sales.todayInEurSum),
        },
        {
          label: 'Sales made in HUF',
          value: formatForint(Math.round(account.sales.totalInHuf / 10) * 10),
        },
        {
          label: 'Sales made in EUR',
          value: formatEuro(account.sales.totalInEur),
        },
        {
          label: 'Total Sales in HUF',
          value: formatForint(Math.round(account.sales.totalInHufSum / 10) * 10),
        },
        {
          label: 'Total Sales in EUR',
          value: formatEuro(account.sales.totalInEurSum),
        },
        {
          label: 'Total Error',
          value: 'TBD',
        },
      ],
    );
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {fullName || email}
        </Typography>
        {rows.map(({ label, value }) => (
          <Grid item key={label} style={{ margin: '8px' }}>
            <InfoCardRow label={label} value={value} />
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
  return <InfoCard title={fullName || email} rows={rows} />;
}

UserInfoCard.propTypes = {
  user: Types.user.isRequired,
};

export default UserInfoCard;
