import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import { useAddProductToOrder /* , useAddTicketProductToOrder */ } from '../../../hooks';

import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { ProductsSelect } from '../../../../products/components';
import { FormikCustomerAutocomplete } from '../../../../customers/components';

// function getTicketNumberValue({ event, ticketNumber }) {
//   if (event?.isExternal) {
//     return ticketNumber || '';
//   }
//   return '';
// }

function AddTicketProductToOrder({ ticket = {} }) {
  //const { event, ticketType, customer, elbCardNumber } = ticket;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //const [add] = useAddTicketProductToOrder();
  const [add] = useAddProductToOrder(handleClose);

  // const formik = useFormik({
  //   initialValues: {
  //     // event: event || null,
  //     // ticketType: ticketType || null,
  //     // customer: customer || null,
  //     // withELBCard: Boolean(elbCardNumber),
  //     // ticketNumber: getTicketNumberValue(ticket),
  //     // productId: null,
  //   },
  //   validationSchema: yup.object({
  //     event: yup.string().required(),
  //     ticketType: yup.string().required(),
  //     customer: yup.string().required(),
  //     ticketNumber: yup.string(),
  //     withELBCard: yup.bool(),
  //     productId: yup.number().required(),
  //   }),
  //   onSubmit: async ({ event, customer, ...otherValues }) => {
  //     console.log({ event, customer, ...otherValues });
  //     const variables = {
  //       event: event,
  //       customer: customer,
  //       ...otherValues,
  //     };
  //     return add(variables);
  //   },
  // });
  const formik = useFormik({
    initialValues: {
      productId: null,
      name: null,
      description: null,
      priceInHuf: null,
      priceInEur: null,
      type: 'ticket',
      imageUrl: null,
      eventId: null,
      isWebshopPurchasable: false,
      includedInOrderEmail: false,
      isMembershipGranted: false,
      membershipExpiresInDays: null,
      customer: null,
    },
    validationSchema: yup.object({
      productId: yup.string().required(),
      name: yup.string().required(),
      description: yup.string().required(),
      priceInHuf: yup
        .number()
        .nullable()
        .optional(),
      priceInEur: yup
        .number()
        .nullable()
        .optional(),
      type: yup.string().required(),
      imageUrl: yup.string().nullable(),
      eventId: yup.string().nullable(),
      isWebshopPurchasable: yup.boolean(),
      includedInOrderEmail: yup.boolean(),
      isMembershipGranted: yup.boolean(),
      membershipExpiresInDays: yup.number().nullable(),
      customer: yup.string().required(),
    }),
    onSubmit: async () => {
      return add(formik.values);
    },
  });

  const onProductSelect = product => {
    if (product !== null) {
      // formik.setFieldValue('event', product.eventId ? product.eventId : null);
      // formik.setFieldValue('ticketType', product.name ? product.name : null);
      formik.setFieldValue('productId', product._id ? product._id : null);
      formik.setFieldValue('name', product.name ? product.name : null);
      formik.setFieldValue('description', product.description ? product.description : null);
      formik.setFieldValue('priceInHuf', product.priceInHuf ? product.priceInHuf : null);
      formik.setFieldValue('priceInEur', product.priceInEur ? product.priceInEur : null);
      formik.setFieldValue('imageUrl', product.imageUrl ? product.imageUrl : null);
      formik.setFieldValue('eventId', product.eventId ? product.eventId : null);
      formik.setFieldValue('isWebshopPurchasable', product.isWebshopPurchasable);
      formik.setFieldValue('includedInOrderEmail', product.includedInOrderEmail);
      formik.setFieldValue('isMembershipGranted', product.isMembershipGranted);
      formik.setFieldValue(
        'membershipExpiresInDays',
        product.membershipExpiresInDays ? product.membershipExpiresInDays : null,
      );
    }
  };

  const onCustomerSelect = customer => {
    formik.setFieldValue('customer', customer.id);
  };
  const handleCustomerChange = useCallback(
    (_, newCustomer, handleChange) => {
      // const activeElbCard = newCustomer && newCustomer.activeElbCard;
      // if (!activeElbCard) {
      //   formik.setFieldValue('withELBCard', false);
      // }
      handleChange();
      console.log({ newCustomer });
      onCustomerSelect(newCustomer);
    },
    [onCustomerSelect],
  );

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Add Ticket
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add Ticket</DialogTitle>
        <Formik initialValues={formik.initialValues}>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  marginBottom={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                >
                  <ProductsSelect type={'ticket'} onProductSelect={onProductSelect} />
                </Box>
                <FormikCustomerAutocomplete
                  name="customer"
                  onChange={handleCustomerChange}
                  toggleElbCard
                  InputProps={{
                    variant: 'outlined',
                    fullWidth: true,
                    margin: 'normal',
                  }}
                />
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="name"
                    name="name"
                    type="hidden"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="description"
                    name="description"
                    type="hidden"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="priceInHuf"
                    name="priceInHuf"
                    type="hidden"
                    value={formik.values.priceInHuf}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="priceInEur"
                    name="priceInEur"
                    type="hidden"
                    value={formik.values.priceInEur}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField id="type" name="type" type="hidden" value={formik.values.type} />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="imageUrl"
                    name="imageUrl"
                    type="hidden"
                    value={formik.values.imageUrl}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="eventId"
                    name="eventId"
                    type="hidden"
                    value={formik.values.eventId}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="isWebshopPurchasable"
                    name="isWebshopPurchasable"
                    type="hidden"
                    value={formik.values.isWebshopPurchasable}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="includedInOrderEmail"
                    name="includedInOrderEmail"
                    type="hidden"
                    value={formik.values.includedInOrderEmail}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="isMembershipGranted"
                    name="isMembershipGranted"
                    type="hidden"
                    value={formik.values.isMembershipGranted}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="membershipExpiresInDays"
                    name="membershipExpiresInDays"
                    type="hidden"
                    value={formik.values.membershipExpiresInDays}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="customer"
                    name="customer"
                    type="hidden"
                    value={formik.values.customer}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="productId"
                    name="productId"
                    type="hidden"
                    value={formik.values.productId}
                    onChange={formik.handleChange}
                  />
                </Box>
                {/* <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="event"
                    name="event"
                    type="hidden"
                    value={formik.values.event}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="ticketType"
                    name="ticketType"
                    type="hidden"
                    value={formik.values.ticketType}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <TextField
                    id="customer"
                    name="customer"
                    type="hidden"
                    value={formik.values.customer}
                    onChange={formik.handleChange}
                  />
                </Box>*/}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isValid && !formik.dirty}
              >
                Add Ticket
              </Button>
            </DialogActions>
          </form>
        </Formik>
      </Dialog>
    </>
  );
}

export default AddTicketProductToOrder;
