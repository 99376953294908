import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useCustomerOrders(customerId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['customer-orders', customerId],
        queryFn: async () => {
            const response = await axios.get(`${REACT_APP_SERVER_URL}customers/${customerId}/orders`);
            return response?.data;
        },
        refetchOnWindowFocus: false,
    });
}
