import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useEffectOnSuccess } from '../../../../lib/hooks';
import { useCompleteProductOrderWithPaidAmounts } from '../../../hooks';
import { ActionButtonWithConfirmation } from '../../../../common/components';
import { PaymentModes } from '../OrderProductChangeCalculator/OrderProductChangeCalculator';

function FinalizeProductOrder({ order, paidAmounts, terminal, paymentMode }) {
  const history = useHistory();

  const [completeProductOrderWithPaidAmounts, res] = useCompleteProductOrderWithPaidAmounts({
    payment: {
      cashSubtotal: paidAmounts.cash,
      cardSubtotal: paidAmounts.card,
    },
    terminal,
  });

  useEffectOnSuccess(res, () => {
    history.push('/open-order-with-products');
  });

  const buttonProps = {
    variant: 'contained',
    color: 'primary',
    disabled: order.isEmpty,
  };

  const mapAmountFromPaymentMode = () => {
    if (paymentMode === PaymentModes.CASH) {
      return {
        payment: {
          cashSubtotal: fullPrice,
          cardSubtotal: 0,
        },
        terminal,
      };
    } else if (paymentMode === PaymentModes.CARD) {
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: fullPrice,
        },
        terminal,
      };
    } else if (paymentMode === PaymentModes.MIXED) {
      return {
        payment: {
          cashSubtotal: parseInt(paidAmounts.cash),
          cardSubtotal: parseInt(paidAmounts.card),
        },
        terminal,
      };
    } else {
      // We should not be here in the else branch
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: 0,
        },
        terminal,
      };
    }
  };

  //TODO: Sum priceInEur too
  const [fullPrice, setFullPrice] = useState(0);
  useEffect(() => {
    const ticketsPriceInHuf = order.products
      .filter(product => product.type === 'ticket')
      .reduce((sum, { priceInHuf }) => sum + priceInHuf, 0);
    const elbCardsPriceInHuf = order.products
      .filter(product => product.type === 'elb-card')
      .reduce((sum, { priceInHuf }) => sum + priceInHuf, 0);
    const ticketsPriceInEur = order.products
      .filter(product => product.type === 'ticket')
      .reduce((sum, { priceInEur }) => sum + priceInEur, 0);
    const elbCardsPriceInEur = order.products
      .filter(product => product.type === 'elb-card')
      .reduce((sum, { priceInEur }) => sum + priceInEur, 0);
    console.log({ elbCardsPriceInHuf, ticketsPriceInHuf, elbCardsPriceInEur, ticketsPriceInEur });
    setFullPrice(ticketsPriceInHuf + elbCardsPriceInHuf);
  }, [order, paidAmounts]);

  const calculateDisabledStateOfButton = () => {
    switch (paymentMode) {
      case PaymentModes.CASH: {
        return false;
      }
      case PaymentModes.CARD: {
        return false;
      }
      case PaymentModes.MIXED: {
        const amountsEquality =
          parseInt(paidAmounts.cash) + parseInt(paidAmounts.card) === fullPrice;
        const result = !(amountsEquality && terminal !== null);
        return result;
      }
      default:
        return false;
    }
  };

  return (
    <ActionButtonWithConfirmation
      action={() => completeProductOrderWithPaidAmounts(mapAmountFromPaymentMode())}
      result={res}
      buttonText="Finalize"
      buttonProps={buttonProps}
      successMessage="Order has been completed!"
      disabled={calculateDisabledStateOfButton()}
    />
  );
}

export default FinalizeProductOrder;
