import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useUpdateCustomerEmail(customerId) {
    return useMutation({
        mutationKey: ['update-customer-email'],
        mutationFn: async payload => {
            console.log({ payload });
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.post(`${REACT_APP_SERVER_URL}customers/${customerId}`,
                payload,
            );
            const data = await response.data;
            console.log({ data });
            return data;
        },
        refetchOnWindowFocus: false,
    });
}
