import React from 'react';

import routes from '../../../../utils/routes';

import { GET_CUSTOMERS } from '../../graphql';

import { Table, CollectionTable } from '../../../common/components';
import { formatDate } from '../../../../utils/formatters';

const columns = [
  {
    name: 'fullName',
    label: 'Full name',
    options: {
      filterType: 'textField',
      renderCell: (value, { id }) => routes.customer.createLink(id, value),
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      filterType: 'textField',
    },
  },
  {
    name: 'activeElbCard.status',
    label: 'Has valid ELB Card',
    options: {
      renderCell: activeElbCard => (activeElbCard ? 'Yes' : 'No'),
      filter: false,
    },
  },
  {
    name: 'totalSalesInHuf',
    label: 'Sales made in HUF',
    type: 'currency',
  },
  {
    name: 'totalSalesInEur',
    label: 'Sales made in EUR',
    type: 'currency_eur',
  },
  {
    name: 'totalSalesInHufSum',
    label: 'Total Sales in HUF',
    type: 'currency',
  },
  {
    name: 'totalSalesInEurSum',
    label: 'Total Sales in HUF',
    type: 'currency_eur',
  },
  {
    name: 'createdDate',
    label: 'Created Date',
    type: 'dateTime',
    options: {
      renderCell: date => formatDate(date),
    },
  },
];

function CustomersTable() {
  return (
    <CollectionTable>
      <Table
        title="Customers List"
        collectionName="customers"
        query={GET_CUSTOMERS}
        columns={columns}
        options={{
          enableSearch: false
        }}
      />
    </CollectionTable>
  );
}

export default CustomersTable;
