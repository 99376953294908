import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetMergedOrderTickets = ({ orderId, eventId, page, rowsPerPage }) => {
    return useQuery({
        queryKey: ['merged-tickets', [{ orderId, eventId, page, rowsPerPage }]],
        queryFn: async () => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            let data;
            if (orderId) {
                const response = await axios.get(
                    `${REACT_APP_SERVER_URL}order/${orderId}/tickets`,
                );
                data = await response.data;
            }
            if (eventId) {
                const response = await axios.get(
                    `${REACT_APP_SERVER_URL}event/${eventId}/tickets?page=${page}&rowsPerPage=${rowsPerPage}`,
                );
                data = await response.data;
            }

            return data;
        },
        refetchOnWindowFocus: false,
    });
};