import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { GET_USER, GET_PROFILE, GET_OTHER_USER } from '../../graphql';

import { useIsMe } from '../../../auth/hooks';
import { useCheckPrivileges } from '../../../lib/hooks';

import { Document, WithDocumentQuery } from '../../../common/components';
import { OrdersTable } from '../../../orders/components';
import { AuditLogsTable } from '../../../audit/components';
import { TransactionsTable } from '../../../transactions/components';
import { UserInfoCard, UserToolbar, ProfileToolbar } from '..';

function User({
  match: {
    params: { id },
  },
}) {
  const isMe = useIsMe(id);

  const [
    ownAuditLogAccess,
    ownOrderAccess,
    anyAuditLogAccess,
    anyOrderAccess,
    anyTransactionAccess,
    createAnyAutidLog,
  ] = useCheckPrivileges(
    Privileges.READ_OWN_AUDIT_LOG,
    Privileges.READ_OWN_ORDER,
    Privileges.READ_ANY_AUDIT_LOG,
    Privileges.READ_ANY_ORDER,
    Privileges.READ_ANY_TRANSACTION,
    Privileges.CREATE_ANY_AUDIT_LOG,
  );

  let query;
  if (anyAuditLogAccess && anyOrderAccess && anyTransactionAccess) {
    /** Current user has access to any user, audit log and order */
    query = GET_USER;
  } else if (isMe && ownAuditLogAccess && ownOrderAccess) {
    /** Requested user's ID equals current user ID */
    query = GET_PROFILE;
  } else if (!isMe && !anyAuditLogAccess && !anyOrderAccess) {
    /**
     * Requested user's ID does not equal current user ID and
     * current user doesn't have access to order and audit log info
     */
    query = GET_OTHER_USER;
  }

  if (!query) return null;
  return (
    <WithDocumentQuery id={id} query={query}>
      {(user, orders, accountAuditLogs, transactions) => {
        // Handle audit logs
        let auditLogEntries = accountAuditLogs?.entries ? [...accountAuditLogs.entries] : [];
        if (accountAuditLogs && accountAuditLogs.entries) {
          auditLogEntries = [...accountAuditLogs.entries].sort((a, b) => {
            return parseInt(b.auditLogSeqId) - parseInt(a.auditLogSeqId);
          });
        }
        // Handle orders
        let ordersEntries = orders?.entries ? [...orders.entries] : [];
        if (orders && orders.entries) {
          ordersEntries = [...orders.entries].sort((a, b) => {
            const createdAtA = new Date(a.createdAt).getTime();
            const createdAtB = new Date(b.createdAt).getTime();
            return createdAtB - createdAtA;
          });
        }
        // Handle transactions
        let transactionsEntries = transactions?.entries ? [...transactions.entries] : [];
        if (transactions && transactions.entries) {
          transactionsEntries = [...transactions.entries].sort((a, b) => {
            const createdAtA = new Date(a.createdAt).getTime();
            const createdAtB = new Date(b.createdAt).getTime();
            return createdAtB - createdAtA;
          });
        }
        return (
          <Document
            toolbar={
              isMe ? (
                <ProfileToolbar user={user} />
              ) : (
                <UserToolbar user={user} auditable={createAnyAutidLog} />
              )
            }
            infoCard={<UserInfoCard user={user} />}
            inlineTable={
              accountAuditLogs && <AuditLogsTable inline auditLogs={auditLogEntries} />
            }
            table={orders && <OrdersTable orders={ordersEntries} />}
            secondTable={transactions && <TransactionsTable transactions={transactionsEntries} />}
          />
        );
      }}
    </WithDocumentQuery>
  );
}

User.propTypes = {
  match: Types.idMatch.isRequired,
};

export default User;
